import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, EMPTY, filter, from, map, switchMap } from 'rxjs';

import { UserConfigsStoreFacade } from './user-configs-store.facade';
import {
  externalUserCountryLoaded,
  externalUserEmployerLoaded,
  superAdminCountriesLoaded,
  superAdminDataLoaded,
  userDataLoaded
} from './user-configs.actions';
import { CustomerIdTypes } from '../../shared/enums/customer-id-types.enum';
import { UserRolesEnum } from '../../shared/enums/user-roles/user-roles.enum';
import { CountryConfig } from '../../shared/interfaces/country-config/country-config';
import { CustomerConfig } from '../../shared/interfaces/customer/customer-config';
import { User } from '../../shared/interfaces/user/user';
import { CountrySetupService } from '../../shared/services/country/country-setup.service';
import { CustomerService } from '../../shared/services/customer/customer.service';
import { HelperService } from '../../shared/services/utils/helper.service';
import * as UserConfigsActions from '../user-configs/user-configs.actions';

@Injectable()
export class UserConfigsEffects {

  constructor(
    private readonly actions$: Actions,
    private readonly customerService: CustomerService,
    private readonly countryService: CountrySetupService,
    private readonly userConfigsFacade: UserConfigsStoreFacade
  ) { }

  public handleSuperAdminData$ = createEffect(() => this.actions$.pipe(
    ofType(superAdminDataLoaded),
    map(({ user }: { user: User }) => {
      const superAdminSelectedView = this.userConfigsFacade.superAdminSelectedViewSignal();
      const superAdminSelectedCountry = this.userConfigsFacade.superAdminSelectedCountrySignal();
      const superAdminSelectedCustomer = this.userConfigsFacade.superAdminSelectedCustomerSignal();

      return userDataLoaded({
        user: superAdminSelectedView === 'EXTERNAL'
          ? { ...user,
            role: UserRolesEnum.SUPER_ADMIN_AS_EXTERNAL,
            userEmployers: [ superAdminSelectedCustomer?.erpRef ?? '' ],
            userCountries: [ superAdminSelectedCountry?.code ?? '' ]}
          : user
      });
    })
  ));

  public loadExternalUserEmployer$ = createEffect(() => this.actions$.pipe(
    ofType(userDataLoaded),
    filter(({ user }) => HelperService.isExternalUser(user?.role)),
    switchMap(({ user }) => this.customerService.getCustomerConfig(user?.userEmployers[0], CustomerIdTypes.erpNumber)
      .pipe(
        concatMap((externalUserEmployerConfig: CustomerConfig) => from([
          externalUserEmployerLoaded({ externalUserEmployerConfig }),
        ]))
      )
    ))
  );

  public loadExternalUserCountry$ = createEffect(() => this.actions$.pipe(
    ofType(externalUserEmployerLoaded),
    switchMap(({ externalUserEmployerConfig }) => this.countryService.getCountryConfig(externalUserEmployerConfig.countryCode)
      .pipe(
        map((externalUserCountryConfig) => externalUserCountryLoaded({ externalUserCountryConfig }))
      )
    ))
  );

  public loadSuperAdminCountries = createEffect(() => this.actions$.pipe(
    ofType(UserConfigsActions.superAdminDataLoaded),
    switchMap(() =>
      this.countryService.getAllCountryConfigs().pipe(
        map((configurations: CountryConfig[]) => {
          const superAdminCountries = configurations.map((configuration) => configuration.country);
          return superAdminCountriesLoaded({ superAdminCountries });
        }),
        catchError(() => EMPTY)
      ))));
}
