import { ProcessStatusExternal } from './process-status-external.enum';
import { ProcessStatusInternal } from './process-status-internal.enum';

export type ProcessStatus = keyof typeof ProcessStatuses;


export const ProcessStatuses = {
  ...ProcessStatusInternal,
  ...ProcessStatusExternal
};

export const activeProcessStatuses: Array<ProcessStatus> = Object.values(ProcessStatuses)
  .filter((status) => status !== ProcessStatusExternal.CLOSED && status !== ProcessStatusExternal.CANCELLED);
