import { AuthConfig } from 'angular-oauth2-oidc';

import { environment } from '../../environments/environment';
import { ROUTES } from '../shared/constants/routes.constants';

export const authCodeFlowConfig: AuthConfig = {
  // Url of the Identity Provider
  issuer: environment.auth.issuer,
  logoutUrl: environment.auth.logoutUrl,

  // URL of the SPA to redirect the user to after login
  redirectUri: window.location.origin + '/' + ROUTES.REDIRECT_URI,
  postLogoutRedirectUri: window.location.origin,
  clientId: environment.auth.clientId,
  responseType: 'code',
  disableAtHashCheck: true,
  clearHashAfterLogin: false,
  timeoutFactor: 0.9,
  silentRefreshRedirectUri:
    window.location.origin + '/' + ROUTES.SILENT_REFRESH,
  preserveRequestedRoute: true,

  // set the scope for the permissions the client should request
  // The first four are defined by OIDC.
  // Important: Request offline_access to get a refresh token
  // The api scope is a usecase specific one
  // to delete scope: 'openid profile email offline_access api',

  scope: environment.auth.scope,
  showDebugInformation: !environment.production,
  strictDiscoveryDocumentValidation: false,
  skipIssuerCheck: true
};
