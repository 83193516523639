import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';

import { UserConfigsStoreFacade } from '../../store/user-configs/user-configs-store.facade';

// If Super-Admin-View header is missing, all Super-Admin headers are ignored.
// If Super-Admin-View: INTERNAL => Super-Admin-Country-Code is required.
// If Super-Admin-View: EXTERNAL => Super-Admin-Customer-Erp-Number is required.

export const superAdminInterceptor: HttpInterceptorFn = (request, next) => {
  const userConfigsFacade = inject(UserConfigsStoreFacade);

  const selectedView = userConfigsFacade.superAdminSelectedViewSignal();
  const selectedCountry = userConfigsFacade.superAdminSelectedCountrySignal();
  const selectedCustomer = userConfigsFacade.superAdminSelectedCustomerSignal();

  if (
    userConfigsFacade.isSuperAdminAsSignal()
    && (request.url.includes('/replenishment/') || request.url.includes('/search/'))
  ) {
    let headers = request.headers;

    if (selectedView) {
      headers = headers.append('Super-Admin-View', selectedView);
    }
    if (selectedCountry) {
      headers = headers.append('Super-Admin-Country-Code', selectedCountry.code);
    }
    if (selectedCustomer) {
      headers = headers.append('Super-Admin-Customer-Erp-Number', selectedCustomer.erpRef);
    }

    return next(request.clone({ headers }));
  }

  return next(request);
};
