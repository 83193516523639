import { EntityState } from '@ngrx/entity';
import { createSelector, MemoizedSelector } from '@ngrx/store';

import { StatusMapState } from './status-map.reducer';
import { ProcessStatus } from '../../shared/enums/replenishment-process/process-status';
import { StatusMapEssentialInterface, StatusMapInterface } from '../../shared/interfaces/replenishment-process/status-map.interface';
import { getState, State } from '../index';

const getStatusState = createSelector(getState, (state: State) => state.statusMap);

const getExternalUserState = createSelector(getStatusState, (state: StatusMapState) => state.externalUserStates);
const getInternalUserState = createSelector(getStatusState, (state: StatusMapState) => state.internalUserStates);

export const getExternalUserNextStates =
  (currentStatus: StatusMapEssentialInterface): MemoizedSelector<State, Array<ProcessStatus>> => createSelector(
    getExternalUserState,
    (state: EntityState<StatusMapInterface>) =>
      state?.entities[`${ currentStatus.replenishmentType } - ${ currentStatus.currentStatus }`]?.nextPossible || []);

export const getInternalUserNextStates =
  (currentStatus: Omit<StatusMapInterface, 'nextPossible' | 'viewPoint'>): MemoizedSelector<State, Array<ProcessStatus>> => createSelector(
    getInternalUserState,
    (state: EntityState<StatusMapInterface>) => getNextStatuses(currentStatus, state)
  );

export const getExternalCommonNextStates = (currentStatuses: Array<StatusMapEssentialInterface>):
  MemoizedSelector<State, Array<ProcessStatus>> => createSelector(
  getExternalUserState,
  (state: EntityState<StatusMapInterface>) => {
    return handleCommonStatuses(currentStatuses, state);
  }
);

export const getInternalCommonNextStates = (currentStatuses: Array<StatusMapEssentialInterface>):
  MemoizedSelector<State, Array<ProcessStatus>> => createSelector(
  getInternalUserState,
  (state: EntityState<StatusMapInterface>) => {
    return handleCommonStatuses(currentStatuses, state);
  }
);

const getNextStatuses =
  (statusData: Omit<StatusMapInterface, 'nextPossible' | 'viewPoint'>, state: EntityState<StatusMapInterface>): Array<ProcessStatus> =>
    state?.entities[`${statusData.replenishmentType} - ${statusData.currentStatus}`]?.nextPossible || [];

const handleCommonStatuses =
  (currentStatuses: Array<StatusMapEssentialInterface>, state: EntityState<StatusMapInterface>): Array<ProcessStatus> => {
    if (currentStatuses.length === 0) {
      return [];
    }
    let firstStatuses = getNextStatuses(currentStatuses[0], state);
    for (let i = 1; i < currentStatuses.length; i++) {
      const nextStatuses = getNextStatuses(currentStatuses[i], state);
      firstStatuses = firstStatuses.filter(status => nextStatuses.includes(status));
    }
    return firstStatuses;
  };
