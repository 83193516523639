import { OVERVIEW_EXTERNAL_CARDS } from './overview/overview-external-cards.constants';
import { OVERVIEW_INTERNAL_CARDS } from './overview/overview-internal-cards.constants';
import { externalProcessTableConfig } from './tables/external-process-table-config';
import { historyTableExternalConfig, historyTableInternalConfig } from './tables/history-process-table-config';
import { internalProcessTableConfig } from './tables/internal-process-table-config';
import { orderListTableExternalConfig } from './tables/order-list-table-config';
import { MandatoryOverviewCardTypes } from '../enums/overview/overview-card-type';
import {
  ProcessTableNamesCustom,
  ProcessTableNamesExternal,
  ProcessTableNamesInternal
} from '../enums/process-tables/process-tables-names.enum';
import { uiConfigKeys } from '../enums/ui-config/ui-config-keys';
import { OverviewCard } from '../interfaces/overview/overview-card.interface';

export const initialUiConfigsIds = [
  ...Object.keys(ProcessTableNamesExternal),
  ...Object.keys(ProcessTableNamesInternal),
  ProcessTableNamesCustom.HISTORY_INTERNAL,
  ProcessTableNamesCustom.HISTORY_EXTERNAL,
  ProcessTableNamesCustom.ORDER_LIST_EXTERNAL,
  uiConfigKeys.OVERVIEW_PAGE_INTERNAL,
  uiConfigKeys.OVERVIEW_PAGE_EXTERNAL
];

export const initialUiConfigs = {
  ...Object.keys(ProcessTableNamesExternal).reduce((acc, key) => ({
    ...acc,
    [key]: { configurationKey: key, configuration: externalProcessTableConfig }
  }), {}),
  ...Object.keys(ProcessTableNamesInternal).reduce((acc, key) => ({
    ...acc,
    [key]: { configurationKey: key, configuration: internalProcessTableConfig }
  }), {}),
  [ProcessTableNamesCustom.HISTORY_INTERNAL]: {
    configurationKey: ProcessTableNamesCustom.HISTORY_INTERNAL,
    configuration: historyTableInternalConfig
  },
  [ProcessTableNamesCustom.HISTORY_EXTERNAL]: {
    configurationKey: ProcessTableNamesCustom.HISTORY_EXTERNAL,
    configuration: historyTableExternalConfig
  },
  [ProcessTableNamesCustom.ORDER_LIST_EXTERNAL]: {
    configurationKey: ProcessTableNamesCustom.ORDER_LIST_EXTERNAL,
    configuration: orderListTableExternalConfig
  },
  [uiConfigKeys.OVERVIEW_PAGE_INTERNAL]: {
    configurationKey: uiConfigKeys.OVERVIEW_PAGE_INTERNAL,
    configuration: {
      visibleStatuses: OVERVIEW_INTERNAL_CARDS
        .filter((card: OverviewCard) => !MandatoryOverviewCardTypes.includes(card.type))
        .map((card: OverviewCard) => card.type)
    }
  },
  [uiConfigKeys.OVERVIEW_PAGE_EXTERNAL]: {
    configurationKey: uiConfigKeys.OVERVIEW_PAGE_EXTERNAL,
    configuration: {
      visibleStatuses: OVERVIEW_EXTERNAL_CARDS
        .filter((card: OverviewCard) => !MandatoryOverviewCardTypes.includes(card.type))
        .map((card: OverviewCard) => card.type)
    }
  }
};
