import { Injectable } from '@angular/core';

import { HttpService } from '@bbraun/helpex/src/lib/http';
import { DateTime } from 'luxon';
import { map, Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { ProcessStatus } from '../../../shared/enums/replenishment-process/process-status';
import { ProcessTypeChangeReasons } from '../../../shared/enums/replenishment-process/process-type-change-reasons.enum';
import { CostEstimateCreateBody } from '../../../shared/interfaces/cost-estimate/cost-estimate-create-body.interface';
import { CostEstimateCreateResponse } from '../../../shared/interfaces/cost-estimate/cost-estimate-create-response.interface';
import { DeliveryNoteCreateBody } from '../../../shared/interfaces/delivery-note/delivery-note';
import { PagingResponseInterface } from '../../../shared/interfaces/layout/paging/paging-response.interface';
import { ProcessTableFiltersResponse } from '../../../shared/interfaces/layout/processes-table/process-filters-dropdown-values';
import { ActivityLogItem } from '../../../shared/interfaces/replenishment-process/activity-log-item';
import {
  BulkStatusUpdateBody
} from '../../../shared/interfaces/replenishment-process/bulk-status-update-body';
import { ErpNumberUpdateBody } from '../../../shared/interfaces/replenishment-process/erp-number-update-body';
import { OrderNumberUpdateBody } from '../../../shared/interfaces/replenishment-process/order-number-update-body.interface';
import {
  ProcessTypeChangeInterface
} from '../../../shared/interfaces/replenishment-process/process-type-change.interface';
import {
  ReplenishmentProcessUpdateBody
} from '../../../shared/interfaces/replenishment-process/replenishment-process-update-body';
import {
  ReplenishmentProcessInterface
} from '../../../shared/interfaces/replenishment-process/replenishment-process.interface';

@Injectable({
  providedIn: 'root'
})
export class ReplenishmentService {

  constructor(private httpService: HttpService) { }

  public getReplenishmentsInOrder(
    filtersBase64: string,
    pageNumber: number,
    pageSize: number,
    sortColumnName: string,
    sortDirection: string): Observable<PagingResponseInterface<ReplenishmentProcessInterface>> {
    return this.httpService.request('v2/replenishments')
      .param('filters', filtersBase64)
      .param('page', pageNumber.toString())
      .param('size', pageSize.toString())
      .param('sort', `${sortColumnName},${sortDirection}`)
      .get(environment.backendUrls.REPLENISHMENT);
  }

  public getReplenishmentsCount(filtersBase64: string): Observable<number> {
    return this.httpService.request('v2/replenishments')
      .param('filters', filtersBase64)
      .param('page', '0')
      .param('size', '1')
      .get(environment.backendUrls.REPLENISHMENT)
      .pipe(map((response: PagingResponseInterface<ReplenishmentProcessInterface>) => response.totalElements));
  }

  public getProcessFiltersDropdownValues(filterString: string): Observable<ProcessTableFiltersResponse> {
    return this.httpService.request('v2/replenishments/filter-values')
      .param('filters', filterString)
      .get(environment.backendUrls.REPLENISHMENT);
  }

  public createDeliveryNote(body: DeliveryNoteCreateBody): Observable<{ noteId: string }> {
    return this.httpService.request('v1/notes')
      .body(body)
      .param('dateTime', DateTime.fromJSDate(new Date()).toISO()!)
      .post(environment.backendUrls.REPLENISHMENT);
  }

  public updateProcessStatusBulk(updateBody: BulkStatusUpdateBody): Observable<void> {
    return this.httpService.request('v2/replenishments/statuses')
      .body(updateBody)
      .put(environment.backendUrls.REPLENISHMENT);
  }

  public updateReplenishmentProcess(processId: string, body: ReplenishmentProcessUpdateBody): Observable<void> {
    return this.httpService.request(`v2/replenishments/${processId}`)
      .body(body)
      .patch(environment.backendUrls.REPLENISHMENT);
  }

  public getServiceNotPossibleReasons(): Observable<Array<ProcessTypeChangeReasons>> {
    return this.httpService.request('v2/replenishments/reasons')
      .get(environment.backendUrls.REPLENISHMENT);
  }

  public updateReplenishmentProcessType(processId: string, body: ProcessTypeChangeInterface): Observable<void> {
    return this.httpService.request(`v2/replenishments/${processId}/type`)
      .body(body)
      .patch(environment.backendUrls.REPLENISHMENT);

  }

  // For cleaning the already entered data we send to the BE null
  // We only send the updated values
  public buildBodyForUpdateRPDetails(
    newOrderNumber: string | undefined,
    newSet: string | undefined,
    newNote: string | undefined,
    newTotalPrice: number | undefined,
    newStatus: ProcessStatus | undefined,
    newRepairGroupCode: string | undefined,
    isInternal: boolean | undefined): ReplenishmentProcessUpdateBody {

    const result: ReplenishmentProcessUpdateBody = {};

    if (newOrderNumber !== undefined) {
      result.orderNumber = newOrderNumber?.length > 0 ? newOrderNumber : null;
    }

    if (newSet !== undefined) {
      result.setInfo = newSet?.length > 0 ? newSet : null;
    }

    if (newTotalPrice) {
      result.totalPrice = newTotalPrice;
    }

    if (newStatus) {
      result.status = newStatus;
    }

    if (newRepairGroupCode) {
      result.repairGroupCode = newRepairGroupCode;
    }

    if (newNote !== undefined) {
      const resultNote = newNote?.length > 0 ? newNote : null;
      isInternal ? result.noteFromBbraun = resultNote : result.noteToBbraun = resultNote;
    }

    return result;
  }

  public createCostEstimate(body: CostEstimateCreateBody): Observable<CostEstimateCreateResponse> {
    return this.httpService.request('v1/cost-estimations')
      .body(body)
      .param('userDateTime', DateTime.fromJSDate(new Date()).toISO()!)
      .post(environment.backendUrls.REPLENISHMENT);
  }

  public changeOrderNumber(body: OrderNumberUpdateBody): Observable<void> {
    return this.httpService.request('v2/replenishments/order-numbers')
      .body(body)
      .patch(environment.backendUrls.REPLENISHMENT);
  }

  public changeErpNumber(body: ErpNumberUpdateBody): Observable<void> {
    return this.httpService.request('v2/replenishments/erp-order-numbers')
      .body(body)
      .patch(environment.backendUrls.REPLENISHMENT);
  }

  public getActivityLog(processId: string): Observable<Array<ActivityLogItem>> {
    return this.httpService.request(`/v2/replenishments/${processId}/activity-log`)
      .get(environment.backendUrls.REPLENISHMENT);
  }
}
