import { Injectable } from '@angular/core';

import { HttpService } from '@bbraun/helpex/src/lib/http';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { ActiveProcessCounts } from '../../interfaces/overview/overview-counts.interface';
import { StatusMapInterface } from '../../interfaces/replenishment-process/status-map.interface';

@Injectable({
  providedIn: 'root'
})
export class ReplenishmentStatusesService {

  constructor(private httpService: HttpService) { }

  public loadExternalUserStatusMap(): Observable<Array<StatusMapInterface>> {
    return this.httpService.request('v1/replenishments/statuses/external')
      .get(environment.backendUrls.REPLENISHMENT);
  }

  public loadInternalUserStatusMap(): Observable<Array<StatusMapInterface>> {
    return this.httpService.request('v1/replenishments/statuses/internal')
      .get(environment.backendUrls.REPLENISHMENT);
  }

  public getOverviewCounts(customerERP?: string): Observable<ActiveProcessCounts> {
    if (customerERP && customerERP.length > 0) {
      return this.httpService
        .request('v1/replenishments/statuses/overview')
        .param('customerErpNumber', customerERP)
        .get(environment.backendUrls.REPLENISHMENT);
    }

    return this.httpService
      .request('v1/replenishments/statuses/overview')
      .get(environment.backendUrls.REPLENISHMENT);
  }
}
