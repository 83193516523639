<cx-sidebar-container
  #switchViewSidebarContainer
  [hideBodyScrollbar]="true">
  <cx-sidebar
    #switchViewSidebar
    [fixedInViewport]="true"
    [opened]="sidebarDisplayStore.switchViewSidebarDisplayed()"
    *transloco="let t">
    <cx-sidebar-header class="d-flex justify-content-between align-items-center">
      <span>{{ t('Switch to External view') }}</span>
    </cx-sidebar-header>
    <cx-sidebar-content>
      <h5 class="mt-0 mb-m"> {{ t('Choose what to open:') }}</h5>
      <mat-form-field class="w-100">
        <mat-label>{{ t('Country') }}</mat-label>
        <mat-select
          required
          [compareWith]="helper.codeMatcher"
          [formControl]="countryControl"
          (selectionChange)="onCountryChange()">
        <mat-option
            *ngFor="let country of userConfigsFacade.superAdminCountriesSignal()"
            [value]="country">
            {{ t(country.name) }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="w-100">
        <mat-label>{{ t('Customer') }}</mat-label>
        <mat-select
          required
          [compareWith]="helper.idMatcher"
          [formControl]="customerControl">
          <mat-option
            *ngFor="let customer of customersSignal()"
            [value]="customer">
            {{ customer?.name ?? '' }}
          </mat-option>
        </mat-select>
        <mat-error>
          {{ t('There are no configured customers for this country.') }}
        </mat-error>
      </mat-form-field>
    </cx-sidebar-content>
    <cx-sidebar-footer>
      <button
        mat-stroked-button
        (click)="closeSidebar()"
        id="change-order-reference-cancel">
        {{ t('Cancel') }}
      </button>
      <button
        mat-flat-button
        class="cx-button-with-icon"
        color="accent"
        (click)="switchView()"
        id="change-order-reference-submit">
        <mat-icon class="cx-icon-in-button" [svgIcon]="'swap_icon_in_button'"/>
        {{ t('Switch') }}
      </button>
    </cx-sidebar-footer>
  </cx-sidebar>
</cx-sidebar-container>
