import { createReducer, on } from '@ngrx/store';

import {
  userDataLoaded,
  externalUserEmployerLoaded,
  externalUserCountryLoaded,
  superAdminCountriesLoaded, superAdminDataLoaded
} from './user-configs.actions';
import { Country } from '../../shared/interfaces/country/country';
import { CountryConfig } from '../../shared/interfaces/country-config/country-config';
import { CustomerConfig } from '../../shared/interfaces/customer/customer-config';
import { User } from '../../shared/interfaces/user/user';

export interface UserConfigsState {
  user: User | undefined;
  externalUserEmployerConfig: CustomerConfig | undefined;
  externalUserCountryConfig: CountryConfig | undefined;
  superAdminCountries: Array<Country>;
  superAdminSelectedView: string | undefined;
  superAdminSelectedCountry: Country | undefined;
  superAdminSelectedCustomer: CustomerConfig | undefined;
}

export const initialUserConfigsState: UserConfigsState = {
  user: undefined,
  externalUserEmployerConfig: undefined,
  externalUserCountryConfig: undefined,
  superAdminCountries: [],
  superAdminSelectedView: undefined,
  superAdminSelectedCountry: undefined,
  superAdminSelectedCustomer: undefined
};

export const userConfigsReducer = createReducer<UserConfigsState>(
  initialUserConfigsState,

  on(userDataLoaded,
    (state: UserConfigsState, actionData: { user: User }) => ({
      ...state,
      user: actionData.user
    })
  ),

  on(externalUserEmployerLoaded,
    (state: UserConfigsState, { externalUserEmployerConfig }) => ({
      ...state,
      externalUserEmployerConfig
    })
  ),

  on(externalUserCountryLoaded,
    (state: UserConfigsState, { externalUserCountryConfig }) => ({
      ...state,
      externalUserCountryConfig
    })
  ),

  on(superAdminCountriesLoaded,
    (state: UserConfigsState, { superAdminCountries }: { superAdminCountries: Array<Country> }) => ({
      ...state,
      superAdminCountries
    })),

  on(superAdminDataLoaded, (state: UserConfigsState) => {
    const superAdminStoredView = localStorage.getItem('super-admin-view');
    const superAdminStoredCountry = localStorage.getItem('super-admin-country');
    const superAdminStoredCustomer = localStorage.getItem('super-admin-customer');

    return {
      ...state,
      superAdminSelectedView: superAdminStoredView ?? 'INTERNAL',
      superAdminSelectedCountry: superAdminStoredCountry ? JSON.parse(superAdminStoredCountry) : { code: 'DE', name: 'Germany' },
      superAdminSelectedCustomer: superAdminStoredCustomer ? JSON.parse(superAdminStoredCustomer) : undefined
    };
  })
);
