export const ROUTES = {
  MAIN: '',
  UNKNOWN: '**',
  WELCOME: 'welcome',
  COUNTRY_SETUP: 'country-setup',
  REPAIR_GROUPS: 'repair-groups',
  REDIRECT_URI: 'auth/callback',
  SILENT_REFRESH: 'silent-refresh',
  PROCESSES: 'processes',
  CREATE_PROCESS: 'create-process',
  HISTORY: 'history',
  OVERVIEW: 'overview',
  ADMIN_PANEL: 'admin-panel',
  CUSTOMER_OVERVIEW: 'customer-overview',
  OVERVIEW_EXTERNAL: 'overview-external',
  OVERVIEW_INTERNAL: 'overview-internal',
  DELIVERY_ADDRESS: 'delivery-address',
  DELIVERY_TIME: 'delivery-time',
  DETAILS: 'details',
  PRICES: 'prices',
  ORGANIZED_PICKUP: 'organized-pickup',
  ADDITIONAL_MARKING: 'additional-marking',
  CONTACT_INFORMATION: 'contact-information',
  HELP: 'help',
  GLOSSARY: 'glossary'
};

export const ROUTE_DATA_CONSTANTS = {
  FALLBACK_ROUTE: 'fallbackRoute', //used to navigate to different route in case the needed rights are not there
  REQUIRED_RIGHTS: 'requiredRights', // mandatory to have all the given rights to access the route
  ONE_NEEDED_RIGHT: 'oneNeededRight' // at least one right must be present to navigate
};
