import { createSelector } from '@ngrx/store';

import { UserConfigsState } from './user-configs.reducer';
import { UserRolesEnum } from '../../shared/enums/user-roles/user-roles.enum';
import { CountryConfig } from '../../shared/interfaces/country-config/country-config';
import { User } from '../../shared/interfaces/user/user';
import { HelperService } from '../../shared/services/utils/helper.service';
import { getState, State } from '../index';

const getUserConfigsState = createSelector(getState, (state: State) => state.userConfigs);

export const getUserEmployer = createSelector(getUserConfigsState, (state: UserConfigsState) => state.externalUserEmployerConfig);
export const getEmployerName = createSelector(getUserEmployer, (employer) => employer?.name);
export const getCompetitionFee = createSelector(getUserEmployer, (customer) => customer?.price?.competitionFee?? 0);
export const getRepairDiscount = createSelector(getUserEmployer, (customer) => customer?.price?.repairDiscount?? 0);
export const getIsMarkingActive = createSelector(getUserEmployer, (customer) => customer?.additionalMarking?.active);

// The currency in the additionalMarking object is being removed in https://jira.bbraun.com/browse/W39TOM-8182, don't use it
export const getMarkingFee = createSelector(getUserEmployer, (customer) => customer?.additionalMarking?.markingFee);

export const getIsDeliveryTimeActive = createSelector(getUserEmployer, (customer) => customer?.deliveryTime.active);
export const getDeliveryTimeRepair = createSelector(getUserEmployer, (customer) => customer?.deliveryTime.dtRepair);
export const getDeliveryTimePurchase = createSelector(getUserEmployer, (customer) => customer?.deliveryTime.dtPurchase);

export const getUser = createSelector(getUserConfigsState, (state: UserConfigsState) => state.user);
export const getIsInternal = createSelector(getUser, (user: User | undefined) => HelperService.isInternalUser(user?.role));
export const getIsExternal = createSelector(getUser, (user: User | undefined) => HelperService.isExternalUser(user?.role));
export const getUserId = createSelector(getUser, (user: User | undefined) => user?.userId);

const getHasUserNoEnterRights = createSelector(
  getUserConfigsState,
  (state: UserConfigsState) => state.user === undefined ||
    state.user.role === undefined ||
    [ UserRolesEnum.NONE, UserRolesEnum.MANY ].includes(state.user.role)
);
export const getHasUserEnterRights = createSelector(getHasUserNoEnterRights, (hasUserNoEnterRights: boolean) => !hasUserNoEnterRights);
export const getUserRole = createSelector(getUserConfigsState, (state: UserConfigsState) => state.user?.role ?? undefined);

export const getIsSuperAdmin = createSelector(getUserRole,
  (role: UserRolesEnum | undefined) => [ UserRolesEnum.SUPER_ADMIN, UserRolesEnum.SUPER_ADMIN_AS_EXTERNAL ].includes(role!));
export const getIsSuperOrCountryAdmin = createSelector(getUserRole,
  (role: UserRolesEnum | undefined) => [ UserRolesEnum.SUPER_ADMIN, UserRolesEnum.SUPER_ADMIN_AS_EXTERNAL, UserRolesEnum.COUNTRY_ADMIN ]
    .includes(role!)
);

export const getExternalUserCountry = createSelector(getUserConfigsState, (state: UserConfigsState) => state.externalUserCountryConfig);
export const getExternalUserCurrencyCode = createSelector(getExternalUserCountry,
  (country: CountryConfig | undefined) => country?.currency.code
);
export const getUserCountries = createSelector(
  getUser,
  (user: User | undefined) => user?.userCountries ?? []);

export const getUserCustomers = createSelector(
  getUser,
  (user: User | undefined) => user?.userCustomers ?? []);

export const getUserLocation = createSelector(
  getUser,
  (user: User | undefined) => user?.location);

export const getSuperAdminCountries = createSelector(
  getUserConfigsState,
  (state: UserConfigsState) => state.superAdminCountries
);

export const getSuperAdminSelectedCountry = createSelector(
  getUserConfigsState,
  (state: UserConfigsState) => state.superAdminSelectedCountry
);

export const getSuperAdminSelectedCustomer = createSelector(
  getUserConfigsState,
  (state: UserConfigsState) => state.superAdminSelectedCustomer
);

export const getSuperAdminSelectedView = createSelector(
  getUserConfigsState,
  (state: UserConfigsState) => state.superAdminSelectedView
);
