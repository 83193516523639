import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';

import { loadReasons, loadReasonsFailure, loadReasonsSuccess } from './reasons.actions';
import { ReplenishmentService } from '../../pages/create-process-page/services/replenishment.service';
import { ProcessTypeChangeReasons } from '../../shared/enums/replenishment-process/process-type-change-reasons.enum';

@Injectable()
export class ReasonsEffects {
  public loadReasons = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadReasons),
      switchMap(() => {
        return this.replenishmentService.getServiceNotPossibleReasons().pipe(
          map((response: Array<ProcessTypeChangeReasons>) => loadReasonsSuccess({ reasons: response })),
          catchError((error: HttpErrorResponse) => of(loadReasonsFailure({ error: error.message }))
          ));
      }
      )
    );
  });

  constructor(
    private actions$: Actions,
    private replenishmentService: ReplenishmentService) {}
}
