import { Component } from '@angular/core';

@Component({
  selector: 'app-silent-refresh',
  standalone: true,
  template: ''
})
export class SilentRefreshComponent {
  constructor() {
    (window.opener || window.parent).postMessage(
      location.hash || '#' + location.search,
      location.origin
    );
  }
}
