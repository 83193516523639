import { NgForOf, Location } from '@angular/common';
import { Component, inject, ViewChild } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

import { CxSidebarComponent, CxSidebarContainerComponent, CxSidebarModule } from '@bbraun/cortex/sidebar';
import { TranslocoDirective } from '@jsverse/transloco';
import { filter, startWith, switchMap, tap } from 'rxjs';

import { ROUTES } from '../../../shared/constants/routes.constants';
import { Country } from '../../../shared/interfaces/country/country';
import { CustomerConfig } from '../../../shared/interfaces/customer/customer-config';
import { CustomerService } from '../../../shared/services/customer/customer.service';
import { HelperService } from '../../../shared/services/utils/helper.service';
import { SidebarDisplayStore } from '../../../shared/signal-stores/sidebar-display-store/sidebar-display.store';
import { UserConfigsStoreFacade } from '../../../store/user-configs/user-configs-store.facade';

@Component({
  selector: 'app-switch-view-sidebar',
  standalone: true,
  imports: [
    CxSidebarModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    TranslocoDirective,
    NgForOf
  ],
  providers: [
    HelperService
  ],
  templateUrl: './switch-view-sidebar.component.html',
  styleUrl: './switch-view-sidebar.component.sass'
})
export class SwitchViewSidebarComponent {

  @ViewChild('switchViewSidebar', { static: false, read: CxSidebarComponent })
  public switchViewSidebar: CxSidebarComponent | undefined;
  @ViewChild('switchViewSidebarContainer', { static: false, read: CxSidebarContainerComponent })
  public switchViewSidebarContainer: CxSidebarContainerComponent | undefined;

  public readonly userConfigsFacade = inject(UserConfigsStoreFacade);
  public readonly helper = inject(HelperService);
  private readonly customerService = inject(CustomerService);
  private readonly ngLocation = inject(Location);
  public sidebarDisplayStore = inject(SidebarDisplayStore);

  public countryControl = new FormControl(
    this.userConfigsFacade.superAdminSelectedCountrySignal(),
    { validators: [ Validators.required ]}
  );

  public customerControl = new FormControl<CustomerConfig | null>(
    null,
    { validators: [ Validators.required ]}
  );

  public customersSignal = toSignal(
    this.countryControl.valueChanges
      .pipe(
        startWith(this.countryControl.value),
        filter((value): value is Country => !!value),
        switchMap(country => this.customerService.getCustomersByCountryCode(country.code)
          .pipe(tap((customers) => this.customerControl.setValue(customers[0]))))
      )
  );

  private readonly window = window;

  public closeSidebar(): void {
    this.sidebarDisplayStore.hideSwitchViewSidebar();
    this.switchViewSidebar?.close();
    this.switchViewSidebarContainer?.updateContentMargins();
  }

  public onCountryChange(): void {
    this.customerControl.setValue(null);
    this.customerControl.markAsUntouched();
  }

  public switchView(): void {
    this.customerControl.markAsTouched();
    if (this.countryControl.value && this.customerControl.value) {
      localStorage.setItem('super-admin-view', 'EXTERNAL');
      localStorage.setItem('super-admin-country', JSON.stringify(this.countryControl.value));
      localStorage.setItem('super-admin-customer', JSON.stringify(this.customerControl.value));
      this.ngLocation.go(ROUTES.WELCOME);
      this.window.location.reload();
    }
  }
}
