import { EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { uiConfigsAdapter } from './adapters/ui-configs.adapter';
import * as LayoutActions from './layout.actions';
import { initialUiConfigs, initialUiConfigsIds } from '../../shared/constants/initial-ui-configs';
import { ROUTES } from '../../shared/constants/routes.constants';
import { CustomerConfig } from '../../shared/interfaces/customer/customer-config';
import { UiConfig } from '../../shared/interfaces/layout/configs/ui-config';
import { HeaderLayout } from '../../shared/interfaces/layout/header/header-layout';

export interface LayoutState {
  overviewPage: {
    selectedCustomer: CustomerConfig | undefined;
  }
  showSplashScreen: boolean;
  header: HeaderLayout;
  hideMainNavigationDrawer: boolean;
  approvalsRequiredCount: number;
  newPurchasesCount: number;
  uiConfigs: EntityState<UiConfig>;
  uiConfigsLoaded: boolean;
  showCountrySwitchSidebar: boolean;
  showViewSwitchSidebar: boolean;
}

export const initialLayoutState: LayoutState = {
  overviewPage: {
    selectedCustomer: undefined
  },
  showSplashScreen: !location.pathname.includes(ROUTES.REDIRECT_URI),
  header: {
    hasBackNavigation: false,
  },
  hideMainNavigationDrawer: false,
  approvalsRequiredCount: 0,
  newPurchasesCount: 0,
  uiConfigs: uiConfigsAdapter.getInitialState({ ids: initialUiConfigsIds, entities: initialUiConfigs }),
  uiConfigsLoaded: false,
  showCountrySwitchSidebar: false,
  showViewSwitchSidebar: false
};

export const layoutReducer = createReducer<LayoutState>(
  initialLayoutState,

  on(LayoutActions.removeSplashScreen,
    (state: LayoutState) => ({
      ...state,
      showSplashScreen: false
    })
  ),

  on(LayoutActions.setHeaderLayout,
    (state: LayoutState, { headerLayout }: { headerLayout: HeaderLayout }) => ({
      ...state,
      header: {
        ...headerLayout
      }
    })
  ),

  on(LayoutActions.mainNavigationDrawerHidden,
    (state: LayoutState, { hideMainNavigationDrawer }: { hideMainNavigationDrawer: boolean }) => ({
      ...state,
      hideMainNavigationDrawer
    })
  ),

  on(LayoutActions.approvalsRequiredCountLoaded,
    (state: LayoutState, { approvalsRequiredCount }: { approvalsRequiredCount: number }) => ({
      ...state,
      approvalsRequiredCount
    })
  ),

  on(LayoutActions.newPurchasesCountLoaded,
    (state: LayoutState, { newPurchasesCount }: { newPurchasesCount: number }) => ({
      ...state,
      newPurchasesCount
    })
  ),

  on(LayoutActions.uiConfigsLoaded,
    (state: LayoutState, { uiConfigs }: { uiConfigs: Array<UiConfig> }) => ({
      ...state,
      uiConfigs: uiConfigsAdapter.setMany(uiConfigs, state.uiConfigs),
      uiConfigsLoaded: true
    })
  ),

  on(LayoutActions.uiConfigChanged,
    (state: LayoutState, { uiConfig }: { uiConfig: UiConfig }) => ({
      ...state,
      uiConfigs: uiConfigsAdapter.upsertOne(uiConfig , state.uiConfigs)
    })
  ),

  on(LayoutActions.setSelectedCustomer,
    (state: LayoutState, { selectedCustomer }: { selectedCustomer: CustomerConfig | undefined }) => ({
      ...state,
      overviewPage: {
        ...state.overviewPage,
        selectedCustomer
      }
    })
  ),

  on(LayoutActions.toggleCountrySwitchSidebar,
    (state: LayoutState, { showCountrySwitchSidebar }: { showCountrySwitchSidebar: boolean }) => ({
      ...state,
      showCountrySwitchSidebar
    })
  ),

  on(LayoutActions.toggleViewSwitchSidebar,
    (state: LayoutState, { showViewSwitchSidebar }: { showViewSwitchSidebar: boolean }) => ({
      ...state,
      showViewSwitchSidebar
    })
  )
);
