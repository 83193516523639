import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { EntitySelectors } from '@ngrx/entity/src/models';

import { StatusMapInterface } from '../../../shared/interfaces/replenishment-process/status-map.interface';

export const statusMapAdapter = createEntityAdapter<StatusMapInterface>({
  selectId: (stateMap: StatusMapInterface) => `${ stateMap.replenishmentType } - ${ stateMap.currentStatus }`
});

export const {
  selectAll
}: EntitySelectors<StatusMapInterface, EntityState<StatusMapInterface>> = statusMapAdapter.getSelectors();
