export enum ProcessStatusExternal {
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  BACKORDER = 'BACKORDER',
  RECEIVED = 'RECEIVED',
  CANCELLED = 'CANCELLED',
  CLOSED = 'CLOSED',
  READY_FOR_SHIPMENT = 'READY_FOR_SHIPMENT',
  SENT = 'SENT',
  PICKUP_SCHEDULED = 'PICKUP_SCHEDULED',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  INTERNAL_SENT = 'INTERNAL_SENT'
}

