import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { CxImageModule } from '@bbraun/cortex/image';
import { CxMessageModule } from '@bbraun/cortex/message';
import { TranslocoDirective } from '@jsverse/transloco';

import { UserRoleReason } from '../../shared/enums/user-roles/user-role-reason.enum';

@Component({
  selector: 'app-no-permission',
  standalone: true,
  imports: [
    CommonModule,
    CxMessageModule,
    TranslocoDirective,
    CxImageModule
  ],
  templateUrl: './no-permission.component.html',
  styleUrls: [ './no-permission.component.sass' ]
})
export class NoPermissionComponent {
  @Input({ required: true }) public reason: UserRoleReason = UserRoleReason.NONE;
}
