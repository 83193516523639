@if (userConfigsFacade.superAdminSelectedCountrySignal(); as country) {
  <div *transloco="let t" class="d-flex mb-sm align-items-center">
    <div class="flag-container">
      <img
        fill
        [alt]="country.name"
        [ngSrc]="'https://purecatamphetamine.github.io/country-flag-icons/1x1/' + country.code + '.svg'"/>
    </div>
    <span class="country-name ml-s">{{ t(country.name) }}</span>
    <button
      mat-icon-button
      class="swap-icon-button"
      [matTooltip]="t(userConfigsFacade.isInternalAsSignal() ? 'Switch the country' : 'Switch')"
      (click)="onSwitchClicked()">
      <mat-icon [svgIcon]="'swap'"></mat-icon>
    </button>
  </div>
}

