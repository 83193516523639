import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { EntitySelectors } from '@ngrx/entity/src/models';

import { ProcessTypeChangeReasons } from '../../../shared/enums/replenishment-process/process-type-change-reasons.enum';

export const reasonsAdapter: EntityAdapter<ProcessTypeChangeReasons> = createEntityAdapter<ProcessTypeChangeReasons>({
  selectId: (reason: ProcessTypeChangeReasons) => reason
});

export const {
  selectAll,
}: EntitySelectors<ProcessTypeChangeReasons, EntityState<ProcessTypeChangeReasons>> = reasonsAdapter.getSelectors();
