import { CommonModule, Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, RouterLink, RouterOutlet } from '@angular/router';

import { CxHeaderModule } from '@bbraun/cortex/header';
import { CxImageModule } from '@bbraun/cortex/image';
import { CxListModule } from '@bbraun/cortex/list';
import { CxNavigationDrawerModule } from '@bbraun/cortex/navigation-drawer';
import { CxSnackbarService } from '@bbraun/cortex/snackbar';
import { CxSplashScreenComponent, CxSplashScreenModule } from '@bbraun/cortex/splash-screen';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { OAuthService } from 'angular-oauth2-oidc';
import { concatMap, filter, from, take } from 'rxjs';

import { authCodeFlowConfig } from './auth/auth-code-flow.config';
import { HeaderProfileComponent } from './components/header-profile/header-profile.component';
import { NavigationExternalComponent } from './components/navigation-external/navigation-external.component';
import { NavigationInternalComponent } from './components/navigation-internal/navigation-internal.component';
import { NoPermissionComponent } from './components/no-permission/no-permission.component';
import { CountrySwitchSidebarComponent } from './components/sidebars/switch-country-sidebar/country-switch-sidebar.component';
import { SwitchViewSidebarComponent } from './components/sidebars/switch-view-sidebar/switch-view-sidebar.component';
import { HistoryPageComponent } from './pages/history-page/history-page.component';
import { APP_NAME } from './shared/constants/application.constants';
import { customIconsList } from './shared/constants/custom-icons';
import { ROUTES } from './shared/constants/routes.constants';
import { UserRoleReason } from './shared/enums/user-roles/user-role-reason.enum';
import { UserRolesEnum } from './shared/enums/user-roles/user-roles.enum';
import { HeaderLayout } from './shared/interfaces/layout/header/header-layout';
import { User } from './shared/interfaces/user/user';
import { AzureInsightsHelperService } from './shared/services/azure-insights-helper/azure-insights-helper.service';
import { AppLanguageService } from './shared/services/language/app-language.service';
import { UserService } from './shared/services/user/user.service';
import { SidebarDisplayStore } from './shared/signal-stores/sidebar-display-store/sidebar-display.store';
import { LayoutStoreFacade } from './store/layout/layout-store.facade';
import { ReasonsStoreFacade } from './store/reasons/reasons-store.facade';
import { StatusMapFacade } from './store/status-maps/status-map.facade';
import { UserConfigsStoreFacade } from './store/user-configs/user-configs-store.facade';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    CxSplashScreenModule,
    CxNavigationDrawerModule,
    CxListModule,
    CxHeaderModule,
    CxImageModule,
    TranslocoDirective,
    MatButtonModule,
    RouterLink,
    MatIconModule,
    MatListModule,
    MatExpansionModule,
    NavigationExternalComponent,
    NoPermissionComponent,
    HistoryPageComponent,
    NavigationInternalComponent,
    MatSlideToggleModule,
    FormsModule,
    HeaderProfileComponent,
    CountrySwitchSidebarComponent,
    MatTooltipModule,
    SwitchViewSidebarComponent
  ],
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.sass' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  @ViewChild('splashScreen') private splashScreen: CxSplashScreenComponent | undefined;

  public readonly sidebarDisplayStore = inject(SidebarDisplayStore);

  public headerLayout!: HeaderLayout;
  public navigationStarted = false;
  public noAccessReason: UserRoleReason | undefined;

  protected isSpashScreenAnimationOver = false;
  protected readonly APP_NAME = APP_NAME;
  protected readonly ROUTES = ROUTES;

  private readonly window = window;

  constructor(
    public readonly layoutStoreFacade: LayoutStoreFacade,
    public readonly userConfigsFacade: UserConfigsStoreFacade,
    private readonly statusMapFacade: StatusMapFacade,
    private readonly languageService: AppLanguageService,
    private readonly oAuthService: OAuthService,
    private readonly snackBarService: CxSnackbarService,
    private readonly userService: UserService,
    private readonly ngLocation: Location,
    private readonly router: Router,
    private readonly transloco: TranslocoService,
    private readonly azureInsightsHelper: AzureInsightsHelperService,
    private readonly vcr: ViewContainerRef,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly destroyRef: DestroyRef,
    private readonly iconRegistry: MatIconRegistry,
    private readonly sanitizer: DomSanitizer,
    private readonly reasonsStoreFacade: ReasonsStoreFacade
  ) {
    this.azureInsightsHelper.handleMonitoring();
  }

  public ngOnInit(): void {
    const language = this.languageService.getCurrent();

    this.transloco.load(language).pipe(take(1)).subscribe();
    this.transloco.setActiveLang(language);

    this.snackBarService.setViewContainerRef(this.vcr);

    this.layoutStoreFacade.header$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((header: HeaderLayout) => {
        this.headerLayout = header;
        this.changeDetector.detectChanges();
      });

    this.oAuthService.configure({ ...authCodeFlowConfig });
    this.oAuthService.setupAutomaticSilentRefresh();

    from(this.oAuthService.loadDiscoveryDocumentAndLogin())
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(() => this.oAuthService.hasValidAccessToken()),
        concatMap(() => this.userService.getUser().pipe(take(1)))
      ).subscribe({
        next: (user: User) => {
          if (this.userService.hasValidRole(user.role)) {
            user.role === UserRolesEnum.SUPER_ADMIN
              ? this.userConfigsFacade.superAdminDataLoaded(user)
              : this.userConfigsFacade.userDataLoaded(user);
            this.router.initialNavigation();
            this.navigationStarted = true;
            this.statusMapFacade.loadExternalUserStatuses();
            this.statusMapFacade.loadInternalUserStatuses();
            this.reasonsStoreFacade.loadProcessTypeChangeReasons();
          } else {
            this.noAccessReason = this.userService.getNoAccessReason(user.role);
          }
          this.closeSplashScreen();
        },
        error: () => {
          this.noAccessReason = UserRoleReason.NONE;
          this.closeSplashScreen();
        }
      });

    customIconsList.forEach(icon => {
      this.iconRegistry.addSvgIcon(
        icon.name,
        this.sanitizer.bypassSecurityTrustResourceUrl(icon.path)
      );
    });
  }

  public navigateBack(backNavigationRoute?: string): void {
    backNavigationRoute
      ? this.router.navigate([ backNavigationRoute ])
      : this.ngLocation.back();
  }

  public onSplashScreenAnimationEnd(): void {
    this.isSpashScreenAnimationOver = true;
    this.layoutStoreFacade.removeSplashScreen();
  }

  public onSwitchButtonClicked(): void {
    this.userConfigsFacade.isInternalAsSignal()
      ? this.sidebarDisplayStore.showSwitchViewSidebar()
      : this.switchToInternalView();
  }

  private switchToInternalView(): void {
    localStorage.setItem('super-admin-view', 'INTERNAL');
    localStorage.setItem('super-admin-country', JSON.stringify(this.userConfigsFacade.superAdminSelectedCountrySignal()));
    localStorage.removeItem('super-admin-customer');
    this.ngLocation.go(ROUTES.WELCOME);
    this.window.location.reload();
  }

  private closeSplashScreen(): void {
    this.splashScreen?.closeSplashScreen();
    // workaround due to issue with output triggered by splashscreen animationend event
    setTimeout(() => {
      if (!this.isSpashScreenAnimationOver) {
        this.onSplashScreenAnimationEnd();
      }
    }, 2000);
  }
}
