export enum ProcessStatusInternal {
  NEW = 'NEW',
  PICKUP_SCHEDULED = 'PICKUP_SCHEDULED',
  IN_PROGRESS = 'IN_PROGRESS',
  IN_WORKSHOP = 'IN_WORKSHOP',
  INTERNAL_SENT = 'INTERNAL_SENT',
  APPROVAL_REQUIRED = 'APPROVAL_REQUIRED',
  BACKORDER = 'BACKORDER',
  READY_FOR_DELIVERY = 'READY_FOR_DELIVERY',
  SERVICE_NOT_POSSIBLE = 'SERVICE_NOT_POSSIBLE'
}
