import { Country } from '../../interfaces/country/country';
import { Currency } from '../../interfaces/country/currency';
import { PaginationParams } from '../../interfaces/layout/tables/pagination-params';

export const SETUP_COUNTRIES: Array<Country> = [
  {
    code: 'FR',
    name: 'France'
  },
  {
    code: 'DE',
    name: 'Germany'
  },
  {
    code: 'MX',
    name: 'Mexico'
  },
  {
    code: 'NL',
    name: 'Netherlands'
  },
  {
    code: 'ES',
    name: 'Spain'
  },
  {
    code: 'CH',
    name: 'Switzerland'
  },
  {
    code: 'GB',
    name: 'United Kingdom'
  }
];

export const COUNTRIES_NAMES: Record<string, string> = Object.fromEntries(SETUP_COUNTRIES.map(({ code, name }) => [ code, name ]));

export const SETUP_CURRENCIES: Array<Currency> = [
  {
    code: 'CHF',
    name: 'Swiss Franc'
  },
  {
    code: 'EUR',
    name: 'Euro'
  },
  {
    code: 'GBP',
    name: 'British Pound'
  },
  {
    code: 'USD',
    name: 'US Dollar'
  }
];

export const REPAIR_GROUP_PAGINATION: PaginationParams = {
  page: 0,
  pageSize: 15
};
