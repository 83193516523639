export const AUTH_LOGOUT_URL =
  'https://test.account.bbraun.com/dadf93c1-1d2f-487d-a595-5d5428a9ae96/b2c_1a__signup_signin_mfa/oauth2/v2.0/logout';
export const AUTH_ISSUER_URL =
  'https://test.account.bbraun.com/dadf93c1-1d2f-487d-a595-5d5428a9ae96/B2C_1A__SIGNUP_SIGNIN_MFA/v2.0/';
export const AUTH_SCOPE_URL =
  'https://test.account.bbraun.com/02ae7625-72d8-423c-9425-d7cd6cd36b68/read';

export const AUTH_QAS_SCOPE_URL =
  'https://test.account.bbraun.com/78896804-cd7a-4f0d-8fa0-47a7e7596399/read';

export const AUTH_PROD_LOGOUT_URL =
  'https://account.bbraun.com/1fa01227-ce45-482e-a1dc-3bfd547280ef/b2c_1a__signup_signin_mfa/oauth2/v2.0/logout';
export const AUTH_PROD_ISSUER_URL =
  'https://account.bbraun.com/1fa01227-ce45-482e-a1dc-3bfd547280ef/B2C_1A__SIGNUP_SIGNIN_MFA/v2.0/';
export const AUTH_PROD_SCOPE_URL =
  'https://account.bbraun.com/75c0242e-738d-4b84-b48d-609c6d1b4beb/read';

export const BACKEND_LOCAL_URLS = {
  COUNTRY: 'http://localhost:8082',
  REPLENISHMENT: 'http://localhost:8083',
  CUSTOMER: 'http://localhost:8084',
  SEARCH: 'http://localhost:8085'
};

export const BACKEND_DEV_URLS = {
  COUNTRY: 'https://dev.resi.bbraun.io/api/v2/country',
  REPLENISHMENT: 'https://dev.resi.bbraun.io/api/v1/replenishment',
  CUSTOMER: 'https://dev.resi.bbraun.io/api/v1/customer',
  SEARCH: 'https://dev.resi.bbraun.io/api/v1/search'
};

export const BACKEND_QAS_URLS = {
  COUNTRY: 'https://qas.resi.bbraun.io/api/v2/country',
  REPLENISHMENT: 'https://qas.resi.bbraun.io/api/v1/replenishment',
  CUSTOMER: 'https://qas.resi.bbraun.io/api/v1/customer',
  SEARCH: 'https://qas.resi.bbraun.io/api/v1/search'
};

export const BACKEND_PROD_URLS = {
  COUNTRY: 'https://resi.bbraun.cloud/api/v2/country',
  REPLENISHMENT: 'https://resi.bbraun.cloud/api/v1/replenishment',
  CUSTOMER: 'https://resi.bbraun.cloud/api/v1/customer',
  SEARCH: 'https://resi.bbraun.cloud/api/v1/search'
};

export const PROFILE_DEV_URL = 'https://myprofile.test.account.bbraun.com';
export const PROFILE_QAS_URL = 'https://myprofile.test.account.bbraun.com';
export const PROFILE_PROD_URL = 'https://myprofile.account.bbraun.com';
