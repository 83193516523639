import { Injectable } from '@angular/core';

import { HttpService } from '@bbraun/helpex/src/lib/http';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { Delivery } from '../../interfaces/country/delivery-address';
import {
  CountryConfig,
  CountryConfigCreateBody
} from '../../interfaces/country-config/country-config';
import { LockStateMessageBody } from '../../interfaces/websocket/websocket-message-bodies';

@Injectable({
  providedIn: 'root'
})
export class CountrySetupService {

  constructor(
    private httpService: HttpService
  ) { }

  public getAllCountryConfigs(): Observable<CountryConfig[]> {
    return this.httpService
      .request('configurations')
      .get(environment.backendUrls.COUNTRY);
  }

  public getCountryConfig(countryCode: string): Observable<CountryConfig> {
    return this.httpService
      .request(`configurations/${countryCode}`)
      .get(environment.backendUrls.COUNTRY);
  }

  public createCountryConfig(body: CountryConfigCreateBody): Observable<CountryConfig> {
    return this.httpService
      .request('configurations')
      .body(body)
      .post(environment.backendUrls.COUNTRY);
  }

  public updateCountryConfigCurrency(countryCode: string, body: { code: string }): Observable<CountryConfig> {
    return this.httpService
      .request(`configurations/${countryCode}/currencies`)
      .body(body)
      .patch(environment.backendUrls.COUNTRY);
  }

  public updateDeliveryAddress(countryCode: string, body: Delivery): Observable<CountryConfig> {
    return this.httpService
      .request(`configurations/${countryCode}/deliveries`)
      .body(body)
      .patch(environment.backendUrls.COUNTRY);
  }

  public getDeliveryAddress(countryCode: string): Observable<Delivery> {
    return this.httpService
      .request(`configurations/${countryCode}/deliveries`)
      .get(environment.backendUrls.COUNTRY);
  }

  public getCountryConfigLockState(countryCode: string): Observable<LockStateMessageBody> {
    return this.httpService
      .request(`uploads/${countryCode}/locks`)
      .get(environment.backendUrls.COUNTRY);
  }
}
