import { EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { statusMapAdapter } from './adapter/status-map.adapter';
import * as stateMapActions from './status-map.actions';
import { StatusMapInterface } from '../../shared/interfaces/replenishment-process/status-map.interface';

export interface StatusMapState {
  externalUserStates: EntityState<StatusMapInterface>;
  internalUserStates: EntityState<StatusMapInterface>;
}

const initialState: StatusMapState = {
  externalUserStates: statusMapAdapter.getInitialState({ ids: [], entities: []}),
  internalUserStates: statusMapAdapter.getInitialState({ ids: [], entities: []})
};

export const statusMapReducer = createReducer(
  initialState,
  on(stateMapActions.ExternalUserStateMapActions.loadSuccess, (state: StatusMapState, { states } ) =>
    ({ ...state, externalUserStates: statusMapAdapter.setAll(states, state.externalUserStates) })),
  on(stateMapActions.ExternalUserStateMapActions.loadFailure, (state: StatusMapState) =>
    ({ ...state, externalUserStates: statusMapAdapter.removeAll(state.externalUserStates) })),

  on(stateMapActions.InternalUserStateMapActions.loadSuccess, (state: StatusMapState, { states } ) =>
    ({ ...state, internalUserStates: statusMapAdapter.setAll(states, state.internalUserStates) })),
  on(stateMapActions.InternalUserStateMapActions.loadFailure, (state: StatusMapState) =>
    ({ ...state, internalUserStates: statusMapAdapter.removeAll(state.internalUserStates) })),
);
