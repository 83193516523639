import { processTablesNames } from '../../enums/process-tables/process-tables-names.enum';

export const historyTableInternalConfig = {
  tableName: processTablesNames.HISTORY_INTERNAL,
  allColumnsInOrder: [
    'type',
    'orderNumber',
    'customer.name',
    'item.code',
    'item.manufacturer',
    'status',
    'audit.createdDate',
    'processId',
    'deliveryNotes.customerNote.noteId',
    'deliveryNotes.workshopNote.noteId',
    'deliveryNotes.bbraunNote.noteId',
    'costEstimation.estimationId',
    'audit.statusModifiedDate',
    'totalPrice.price',
    'item.description',
    'setInfo',
    'serialNumber',
    'erpOrderNumber',
    'notes',
    'quantity',
    'workshop.name',
  ],
  visibleColumnsInOrder: [
    'type',
    'orderNumber',
    'customer.name',
    'item.code',
    'item.manufacturer',
    'status',
    'audit.createdDate',
  ],
  pagination: {
    page: 0,
    pageSize: 15
  },
  sorting: {
    column: 'audit.createdDate',
    direction: 'desc' as const
  },
};

export const historyTableExternalConfig = {
  tableName: processTablesNames.HISTORY_EXTERNAL,
  allColumnsInOrder: [
    'type',
    'orderNumber',
    'item.code',
    'item.manufacturer',
    'item.description',
    'quantity',
    'totalPrice.price',
    'status',
    'audit.createdDate',
    'processId',
    'deliveryNotes.customerNote.noteId',
    'deliveryNotes.bbraunNote.noteId',
    'costEstimation.estimationId',
    'audit.statusModifiedDate',
    'setInfo',
    'serialNumber',
    'notes'
  ],
  visibleColumnsInOrder: [
    'type',
    'item.code',
    'item.manufacturer',
    'item.description',
    'status',
    'audit.createdDate',
  ],
  pagination: {
    page: 0,
    pageSize: 15
  },
  sorting: {
    column: 'audit.createdDate',
    direction: 'desc' as const
  },
};
