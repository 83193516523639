export enum ProcessTableNamesExternal {
  ALL_EXTERNAL = 'ALL_EXTERNAL',
  NEW_EXTERNAL = 'NEW_EXTERNAL',
  IN_PROGRESS_EXTERNAL = 'IN_PROGRESS_EXTERNAL',
  BACKORDER_EXTERNAL = 'BACKORDER_EXTERNAL',
  RECEIVED_EXTERNAL = 'RECEIVED_EXTERNAL',
  READY_FOR_SHIPMENT_EXTERNAL = 'READY_FOR_SHIPMENT_EXTERNAL',
  SENT_EXTERNAL = 'SENT_EXTERNAL',
  PICKUP_SCHEDULED_EXTERNAL = 'PICKUP_SCHEDULED_EXTERNAL',
  REJECTED_EXTERNAL = 'REJECTED_EXTERNAL',
  APPROVED_EXTERNAL = 'APPROVED_EXTERNAL',
  INTERNAL_SENT_EXTERNAL = 'INTERNAL_SENT_EXTERNAL',
  REPAIR_EXTERNAL = 'REPAIR_EXTERNAL',
  REPAIR_REQUEST_EXTERNAL = 'REPAIR_REQUEST_EXTERNAL',
  REPAIR_REPLACEMENT_EXTERNAL = 'REPAIR_REPLACEMENT_EXTERNAL',
  PURCHASE_EXTERNAL = 'PURCHASE_EXTERNAL',
  APPROVAL_REQUIRED_EXTERNAL = 'APPROVAL_REQUIRED_EXTERNAL',
  SERVICE_NOT_POSSIBLE_EXTERNAL = 'SERVICE_NOT_POSSIBLE_EXTERNAL'
}

export enum ProcessTableNamesInternal {
  ALL_INTERNAL = 'ALL_INTERNAL',
  NEW_INTERNAL = 'NEW_INTERNAL',
  IN_PROGRESS_INTERNAL = 'IN_PROGRESS_INTERNAL',
  BACKORDER_INTERNAL = 'BACKORDER_INTERNAL',
  RECEIVED_INTERNAL = 'RECEIVED_INTERNAL',
  READY_FOR_SHIPMENT_INTERNAL = 'READY_FOR_SHIPMENT_INTERNAL',
  SENT_INTERNAL = 'SENT_INTERNAL',
  PICKUP_SCHEDULED_INTERNAL = 'PICKUP_SCHEDULED_INTERNAL',
  REJECTED_INTERNAL = 'REJECTED_INTERNAL',
  APPROVED_INTERNAL = 'APPROVED_INTERNAL',
  INTERNAL_SENT_INTERNAL = 'INTERNAL_SENT_INTERNAL',
  REPAIR_INTERNAL = 'REPAIR_INTERNAL',
  REPAIR_REQUEST_INTERNAL = 'REPAIR_REQUEST_INTERNAL',
  REPAIR_REPLACEMENT_INTERNAL = 'REPAIR_REPLACEMENT_INTERNAL',
  PURCHASE_INTERNAL = 'PURCHASE_INTERNAL',
  IN_WORKSHOP_INTERNAL = 'IN_WORKSHOP_INTERNAL',
  APPROVAL_REQUIRED_INTERNAL = 'APPROVAL_REQUIRED_INTERNAL',
  READY_FOR_DELIVERY_INTERNAL = 'READY_FOR_DELIVERY_INTERNAL',
  SERVICE_NOT_POSSIBLE_INTERNAL = 'SERVICE_NOT_POSSIBLE_INTERNAL'
}

export enum ProcessTableNamesCustom {
  HISTORY_INTERNAL = 'HISTORY_INTERNAL',
  HISTORY_EXTERNAL = 'HISTORY_EXTERNAL',
  ORDER_LIST_EXTERNAL = 'ORDER_LIST_EXTERNAL'
}

export const processTablesNames = {
  ...ProcessTableNamesExternal,
  ...ProcessTableNamesInternal,
  ...ProcessTableNamesCustom
};

export type ProcessTableName = keyof typeof processTablesNames;
