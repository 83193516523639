import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { superAdminDataLoaded, userDataLoaded } from './user-configs.actions';
import * as userConfigSelectors from './user-configs.selectors';
import { User } from '../../shared/interfaces/user/user';
import { State } from '../index';

@Injectable()
export class UserConfigsStoreFacade {
  public externalUserEmployer$ = this.store.select(userConfigSelectors.getUserEmployer);
  public customerName$ = this.store.select(userConfigSelectors.getEmployerName);
  public competitionFee$ = this.store.select(userConfigSelectors.getCompetitionFee);
  public repairDiscount$ = this.store.select(userConfigSelectors.getRepairDiscount);
  public isMarkingActive$ = this.store.select(userConfigSelectors.getIsMarkingActive);
  public markingFee$ = this.store.select(userConfigSelectors.getMarkingFee);
  public isDeliveryTimeActive$ = this.store.select(userConfigSelectors.getIsDeliveryTimeActive);
  public deliveryTimeRepair$ = this.store.select(userConfigSelectors.getDeliveryTimeRepair);
  public deliveryTimePurchase$ = this.store.select(userConfigSelectors.getDeliveryTimePurchase);
  public hasUserEnterRights$ = this.store.select(userConfigSelectors.getHasUserEnterRights);
  public isInternal$ = this.store.select(userConfigSelectors.getIsInternal);
  public isInternalAsSignal = this.store.selectSignal(userConfigSelectors.getIsInternal);
  public isExternal$ = this.store.select(userConfigSelectors.getIsExternal);
  public isExternalAsSignal = this.store.selectSignal(userConfigSelectors.getIsExternal);
  public userRights$ = this.store.select(userConfigSelectors.getUserRole);
  public isSuperAdmin$ = this.store.select(userConfigSelectors.getIsSuperAdmin);
  public isSuperAdminAsSignal = this.store.selectSignal(userConfigSelectors.getIsSuperAdmin);
  public isSuperOrCountryAdmin$ = this.store.select(userConfigSelectors.getIsSuperOrCountryAdmin);
  public user$ = this.store.select(userConfigSelectors.getUser);
  public userId$ = this.store.select(userConfigSelectors.getUserId);
  public userIdAsSignal = this.store.selectSignal(userConfigSelectors.getUserId);
  public userCurrencyCode$ = this.store.select(userConfigSelectors.getExternalUserCurrencyCode);
  public userCountries$ = this.store.select(userConfigSelectors.getUserCountries);
  public userCountriesAsSignal = this.store.selectSignal(userConfigSelectors.getUserCountries);
  public userCustomers$ = this.store.select(userConfigSelectors.getUserCustomers);
  public userLocationSignal = this.store.selectSignal(userConfigSelectors.getUserLocation);
  public superAdminCountries$ = this.store.select(userConfigSelectors.getSuperAdminCountries);
  public superAdminCountriesSignal = this.store.selectSignal(userConfigSelectors.getSuperAdminCountries);
  public superAdminSelectedCountrySignal = this.store.selectSignal(userConfigSelectors.getSuperAdminSelectedCountry);
  public superAdminSelectedCustomerSignal = this.store.selectSignal(userConfigSelectors.getSuperAdminSelectedCustomer);
  public superAdminSelectedViewSignal = this.store.selectSignal(userConfigSelectors.getSuperAdminSelectedView);

  constructor(
    private store: Store<State>
  ) { }

  public userDataLoaded(user: User): void {
    this.store.dispatch(userDataLoaded({ user }));
  }

  public superAdminDataLoaded(user: User): void {
    this.store.dispatch(superAdminDataLoaded({ user }));
  }
}
