export const internalProcessTableConfig = {
  allColumnsInOrder: [
    'type',
    'orderNumber',
    'customer.name',
    'item.code',
    'item.manufacturer',
    'status',
    'audit.createdDate',
    'markingText',
    'processId',
    'deliveryNotes.customerNote.noteId',
    'deliveryNotes.workshopNote.noteId',
    'deliveryNotes.bbraunNote.noteId',
    'costEstimation.estimationId',
    'audit.statusModifiedDate',
    'totalPrice.price',
    'item.description',
    'setInfo',
    'serialNumber',
    'erpOrderNumber',
    'notes',
    'quantity',
    'workshop.name'
  ],
  visibleColumnsInOrder: [
    'type',
    'orderNumber',
    'customer.name',
    'item.code',
    'item.manufacturer',
    'status',
    'audit.createdDate'
  ],
  pagination: {
    page: 0,
    pageSize: 15
  },
  sorting: {
    column: 'audit.createdDate',
    direction: 'desc' as const
  },
};
