import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { HTTP_STATUS_CODES } from '@bbraun/cortex/shared';
import { CxSnackbarService } from '@bbraun/cortex/snackbar';
import { TranslocoService } from '@ngneat/transloco';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable, catchError } from 'rxjs';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  public readonly snackbarService = inject(CxSnackbarService);
  public readonly transloco = inject(TranslocoService);
  public readonly oAuthService = inject(OAuthService);

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error) {
          switch (error.status) {
          case HTTP_STATUS_CODES.UNAUTHORIZED:
            this.oAuthService.logOut();
            break;
          case HTTP_STATUS_CODES.INTERNAL_SERVER_ERROR:
            this.snackbarService.error(this.transloco.translate('Something went wrong.'));
            break;
          }
        }
        throw error;
      })
    );
  }
}
