import { Injectable } from '@angular/core';

import { HttpService } from '@bbraun/helpex/src/lib/http';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { uiConfigKeys } from '../../enums/ui-config/ui-config-keys';
import { UserRoleReason } from '../../enums/user-roles/user-role-reason.enum';
import { UserRolesEnum } from '../../enums/user-roles/user-roles.enum';
import { UiConfig, WelcomePageConfigWithKey } from '../../interfaces/layout/configs/ui-config';
import { User } from '../../interfaces/user/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private httpService: HttpService) { }

  public getUser(): Observable<User> {
    return this.httpService.request('v1/user')
      .get(environment.backendUrls.CUSTOMER);
  }

  public hasValidRole(role: UserRolesEnum): boolean {
    return ![ UserRolesEnum.NONE, UserRolesEnum.MANY ].includes(role);
  }

  public getNoAccessReason(role: UserRolesEnum): UserRoleReason {
    switch (role) {
    case UserRolesEnum.NONE:
      return UserRoleReason.NONE;
    case UserRolesEnum.MANY:
      return UserRoleReason.MANY;
    default:
      return UserRoleReason.NONE;
    }
  }

  public getUiConfigs(userId: string, configurationKey?: string): Observable<{ payload: Array<UiConfig> }> {
    let request = this.httpService.request(`v1/user/${userId}/configurations`);
    if (configurationKey) {
      request = request.param('configurationKey', configurationKey);
    }

    return request.get(environment.backendUrls.CUSTOMER);
  }

  public updateUiConfig(userId: string, uiConfig: UiConfig): Observable<any> {
    return this.httpService.request(`v1/user/${userId}/configurations`)
      .param('configurationKey', uiConfig.configurationKey)
      .body(uiConfig.configuration)
      .put(environment.backendUrls.CUSTOMER);
  }

  public getWelcomePageUiConfig(userId: string): Observable<{ payload: Array<WelcomePageConfigWithKey> }> {
    return this.httpService.request(`v1/user/${userId}/configurations`)
      .param('configurationKey', uiConfigKeys.WELCOME_PAGE)
      .get(environment.backendUrls.CUSTOMER);
  }
}
