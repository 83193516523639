import { CurrencyPipe, DecimalPipe, getCurrencySymbol } from '@angular/common';
import { computed, inject, Pipe, PipeTransform, Signal } from '@angular/core';

import { UserConfigsStoreFacade } from '../../../store/user-configs/user-configs-store.facade';

@Pipe({
  name: 'resiCurrency',
  standalone: true
})
export class ResiCurrencyPipe implements PipeTransform {
  private currencyPipe = inject(CurrencyPipe);
  private decimalPipe = inject(DecimalPipe);
  private userFacade = inject(UserConfigsStoreFacade);

  public transform(value: string | number | undefined | null, currencyCode: string | undefined | null): Signal<string | null> {

    return  computed(() => {
      const symbol = getCurrencySymbol(currencyCode?? '', 'narrow');
      const location = this.userFacade.userLocationSignal();

      return location === 'UK'
        ? this.currencyPipe.transform(value, currencyCode?? '', 'symbol', undefined, 'en')
        : location === 'CH' && value?.toString() // For this case, falsy values except 0 should fall to default
        ? `${symbol}\xa0${this.decimalPipe.transform(value, '1.2-2', 'de')}`
        : this.currencyPipe.transform(value, currencyCode?? '', 'symbol', undefined, 'de');
    });
  }
}
