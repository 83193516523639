import { Injectable, NgModule } from '@angular/core';

import {
  TRANSLOCO_LOADER,
  Translation,
  TranslocoLoader,
  TRANSLOCO_CONFIG,
  translocoConfig,
  TranslocoModule
} from '@ngneat/transloco';

import { environment } from '../../../environments/environment';
import { DEFAULT_LANGUAGE_KEY, languages } from '../constants/language.constants';

@Injectable()
export class TranslocoHttpLoader implements TranslocoLoader {

  public getTranslation(lang: string): Promise<Translation> {
    return import(`../../../assets/i18n/${lang}.json`);
  }
}

@NgModule({
  exports: [ TranslocoModule ],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: [ ...languages ],
        defaultLang: DEFAULT_LANGUAGE_KEY,
        reRenderOnLangChange: true,
        fallbackLang: DEFAULT_LANGUAGE_KEY,
        missingHandler: {
          useFallbackTranslation: true
        },
        prodMode: environment.production
      })
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader }
  ]
})
export class TranslocoRootModule {}
