import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { EntitySelectors } from '@ngrx/entity/src/models';

import { UiConfig } from '../../../shared/interfaces/layout/configs/ui-config';

export const uiConfigsAdapter = createEntityAdapter<UiConfig>({
  selectId: (uiConfig: UiConfig) => uiConfig.configurationKey
});

export const {
  selectAll
}: EntitySelectors<UiConfig, EntityState<UiConfig>> = uiConfigsAdapter.getSelectors();
