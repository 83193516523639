import { Data } from '@angular/router';

import { getRouterSelectors, MinimalRouterStateSnapshot, RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ROUTE_DATA_CONSTANTS } from '../../shared/constants/routes.constants';

export const ROUTER_FEATURE_KEY = 'router';

export const getRouterState = createFeatureSelector<RouterReducerState<MinimalRouterStateSnapshot>>(ROUTER_FEATURE_KEY);
export const getRouteUrl = getRouterSelectors(getRouterState).selectUrl;
export const getRouteData = getRouterSelectors(getRouterState).selectRouteData;
export const getQueryParams = getRouterSelectors(getRouterState).selectQueryParams;

export const getTableNameParam = createSelector(
  getQueryParams,
  (queryParams) => queryParams ? queryParams['tableName'] : undefined);

export const getCustomerParam = createSelector(
  getQueryParams,
  (queryParams) => queryParams ? queryParams['customer'] : undefined);

export const getRouteDataRequiredRights = createSelector(
  getRouteData,
  (routeData: Data) => routeData ? (routeData[ROUTE_DATA_CONSTANTS.REQUIRED_RIGHTS] ?? []) : []);

export const getRouteDataOneNeeded = createSelector(
  getRouteData,
  (routeData: Data) => routeData ? (routeData[ROUTE_DATA_CONSTANTS.ONE_NEEDED_RIGHT] ?? []) : []);

export const getRouteDataFallbackRoute = createSelector(
  getRouteData,
  (routeData: Data) => routeData ? (routeData[ROUTE_DATA_CONSTANTS.FALLBACK_ROUTE] ?? '/') : '/');
