import { Injectable } from '@angular/core';

import { DEFAULT_LANGUAGE_KEY, languages } from '../../constants/language.constants';

@Injectable()
export class AppLanguageService {
  public getCurrent(): string {
    let locale: string = navigator.language;

    if (!locale || typeof locale !== 'string') {
      return DEFAULT_LANGUAGE_KEY;
    }

    locale = locale.split('-')[0];

    return languages.includes(locale)
      ? locale
      : DEFAULT_LANGUAGE_KEY;
  }
}
