import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { StatusMapInterface } from '../../shared/interfaces/replenishment-process/status-map.interface';

export const ExternalUserStateMapActions = createActionGroup({
  source: 'External User State Map',
  events: {
    'Load': emptyProps(),
    'Load Success': props<{ states: Array<StatusMapInterface> }>(),
    'Load Failure': props<{ error: string }>(),
  }
});

export const InternalUserStateMapActions = createActionGroup({
  source: 'Internal User State Map',
  events: {
    'Load': emptyProps(),
    'Load Success': props<{ states: Array<StatusMapInterface> }>(),
    'Load Failure': props<{ error: string }>(),
  }
});
