import { NgOptimizedImage } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

import { TranslocoModule } from '@ngneat/transloco';

import { LayoutStoreFacade } from '../../store/layout/layout-store.facade';
import { UserConfigsStoreFacade } from '../../store/user-configs/user-configs-store.facade';

@Component({
  selector: 'app-navigation-country-switch',
  standalone: true,
  imports: [
    NgOptimizedImage,
    MatIconModule,
    MatButtonModule,
    TranslocoModule,
    MatTooltipModule
  ],
  templateUrl: './navigation-country-switch.component.html',
  styleUrl: './navigation-country-switch.component.sass'
})
export class NavigationCountrySwitchComponent {

  public userConfigsFacade = inject(UserConfigsStoreFacade);
  public layoutFacade = inject(LayoutStoreFacade);

  public onSwitchClicked(): void {
    this.userConfigsFacade.isInternalAsSignal()
      ? this.layoutFacade.openCountrySwitchSidebar()
      : this.layoutFacade.openViewSwitchSidebar();
  }
}
