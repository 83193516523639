import { inject, Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import * as routerSelectors from './router.selectors';

@Injectable()
export class RouterStoreFacade {
  private readonly store = inject(Store);

  public readonly url$ = this.store.select(routerSelectors.getRouteUrl);
  public readonly requiredRights$ = this.store.select(routerSelectors.getRouteDataRequiredRights);
  public readonly oneNeededRights$ = this.store.select(routerSelectors.getRouteDataOneNeeded);
  public readonly noRightFallbackRoute$ = this.store.select(routerSelectors.getRouteDataFallbackRoute);
  public readonly queryParams$ = this.store.select(routerSelectors.getQueryParams);
  public readonly tableNameParam$ = this.store.select(routerSelectors.getTableNameParam);
  public readonly tableNameParamSignal = this.store.selectSignal(routerSelectors.getTableNameParam);
}
