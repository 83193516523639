import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';

import { ExternalUserStateMapActions, InternalUserStateMapActions } from './status-map.actions';
import { StatusMapInterface } from '../../shared/interfaces/replenishment-process/status-map.interface';
import { ReplenishmentStatusesService } from '../../shared/services/replenishment-statuses/replenishment-statuses.service';

@Injectable()
export class StatusMapEffects {

  public loadExternalStatusMap$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ExternalUserStateMapActions.load),
      switchMap(() =>
        this.replenishmentStatusesService.loadExternalUserStatusMap().pipe(
          map((response: Array<StatusMapInterface>) => ExternalUserStateMapActions.loadSuccess({ states: response })),
          catchError((error: HttpErrorResponse) => of(ExternalUserStateMapActions.loadFailure({ error: error.message }))
          ))
      )
    );
  });

  public loadInternalStatusMap$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(InternalUserStateMapActions.load),
      switchMap(() =>
        this.replenishmentStatusesService.loadInternalUserStatusMap().pipe(
          map((response: Array<StatusMapInterface>) => InternalUserStateMapActions.loadSuccess({ states: response })),
          catchError((error: HttpErrorResponse) => of(InternalUserStateMapActions.loadFailure({ error: error.message }))
          ))
      )
    );
  });

  constructor(
    private actions$: Actions,
    private replenishmentStatusesService: ReplenishmentStatusesService) {}
}
