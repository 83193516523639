import { routerReducer, RouterState } from '@ngrx/router-store';
import { ActionReducerMap } from '@ngrx/store';

import { layoutReducer, LayoutState } from './layout/layout.reducer';
import { reasonsReducer, ReasonsState } from './reasons/reasons.reducer';
import { StatusMapState, statusMapReducer } from './status-maps/status-map.reducer';
import { userConfigsReducer, UserConfigsState } from './user-configs/user-configs.reducer';

export interface State {
  router: RouterState;
  layout: LayoutState;
  statusMap: StatusMapState;
  userConfigs: UserConfigsState;
  reasons: ReasonsState;
}

export const reducers: ActionReducerMap<State> = {
  router: routerReducer,
  layout: layoutReducer,
  statusMap: statusMapReducer,
  userConfigs: userConfigsReducer,
  reasons: reasonsReducer
};

export const getState = (state: State): State => state;
