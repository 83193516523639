import { OverviewCardTypes } from '../../enums/overview/overview-card-type';
import { processTablesNames } from '../../enums/process-tables/process-tables-names.enum';
import { ProcessStatuses } from '../../enums/replenishment-process/process-status';
import { OverviewCard } from '../../interfaces/overview/overview-card.interface';
import { ROUTES } from '../routes.constants';

export const OVERVIEW_INTERNAL_CARDS: Array<OverviewCard> = [
  {
    type: OverviewCardTypes.ALL_PROCESSES,
    route: `/${ROUTES.PROCESSES}`,
    queryParams: {
      tableName: processTablesNames.ALL_INTERNAL
    }
  },
  {
    type: OverviewCardTypes.NEW,
    queryParams: {
      tableName: processTablesNames.NEW_INTERNAL,
      status: ProcessStatuses.NEW
    },
    route: `/${ROUTES.PROCESSES}`
  },
  {
    type: OverviewCardTypes.READY_FOR_SHIPMENT,
    queryParams: {
      tableName: processTablesNames.READY_FOR_SHIPMENT_INTERNAL,
      status: ProcessStatuses.READY_FOR_SHIPMENT
    },
    route: `/${ROUTES.PROCESSES}`
  },
  {
    type: OverviewCardTypes.PICKUP_SCHEDULED,
    queryParams: {
      tableName: processTablesNames.PICKUP_SCHEDULED_INTERNAL,
      status: ProcessStatuses.PICKUP_SCHEDULED
    },
    route: `/${ROUTES.PROCESSES}`
  },
  {
    type: OverviewCardTypes.SENT,
    queryParams: {
      tableName: processTablesNames.SENT_INTERNAL,
      status: ProcessStatuses.SENT
    },
    route: `/${ROUTES.PROCESSES}`
  },
  {
    type: OverviewCardTypes.IN_PROGRESS,
    queryParams: {
      tableName: processTablesNames.IN_PROGRESS_INTERNAL,
      status: ProcessStatuses.IN_PROGRESS
    },
    route: `/${ROUTES.PROCESSES}`
  },
  {
    type: OverviewCardTypes.IN_WORKSHOP,
    queryParams: {
      tableName: processTablesNames.IN_WORKSHOP_INTERNAL,
      status: ProcessStatuses.IN_WORKSHOP
    },
    route: `/${ROUTES.PROCESSES}`
  },
  {
    type: OverviewCardTypes.APPROVAL_REQUIRED,
    queryParams: {
      tableName: processTablesNames.APPROVAL_REQUIRED_INTERNAL,
      status: ProcessStatuses.APPROVAL_REQUIRED
    },
    route: `/${ROUTES.PROCESSES}`
  },
  {
    type: OverviewCardTypes.APPROVED,
    queryParams: {
      tableName: processTablesNames.APPROVED_INTERNAL,
      status: ProcessStatuses.APPROVED
    },
    route: `/${ROUTES.PROCESSES}`
  },
  {
    type: OverviewCardTypes.REJECTED,
    queryParams: {
      tableName: processTablesNames.REJECTED_INTERNAL,
      status: ProcessStatuses.REJECTED
    },
    route: `/${ROUTES.PROCESSES}`
  },
  {
    type: OverviewCardTypes.BACKORDER,
    queryParams: {
      tableName: processTablesNames.BACKORDER_INTERNAL,
      status: ProcessStatuses.BACKORDER
    },
    route: `/${ROUTES.PROCESSES}`
  },
  {
    type: OverviewCardTypes.SERVICE_NOT_POSSIBLE,
    queryParams: {
      tableName: processTablesNames.SERVICE_NOT_POSSIBLE_INTERNAL,
      status: ProcessStatuses.SERVICE_NOT_POSSIBLE
    },
    route: ROUTES.PROCESSES
  },
  {
    type: OverviewCardTypes.READY_FOR_DELIVERY,
    queryParams: {
      tableName: processTablesNames.READY_FOR_DELIVERY_INTERNAL,
      status: ProcessStatuses.READY_FOR_DELIVERY
    },
    route: `/${ROUTES.PROCESSES}`
  },
  {
    type: OverviewCardTypes.INTERNAL_SENT,
    queryParams: {
      tableName: processTablesNames.INTERNAL_SENT_INTERNAL,
      status: ProcessStatuses.INTERNAL_SENT
    },
    route: `/${ROUTES.PROCESSES}`
  },
  {
    type: OverviewCardTypes.RECEIVED,
    queryParams: {
      tableName: processTablesNames.RECEIVED_INTERNAL,
      status: ProcessStatuses.RECEIVED
    },
    route: `/${ROUTES.PROCESSES}`
  }
];
