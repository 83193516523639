import { Injectable } from '@angular/core';

import { Workshop } from '../../interfaces/workshop/workshop';

@Injectable({
  providedIn: 'root'
})
export class CommonMethodsService {

  public static prepareFiltersBase64String(filter: object): string {
    const correctFilterFormat = { filters: filter };
    const filterJson = JSON.stringify(correctFilterFormat);
    return btoa(unescape(encodeURIComponent(filterJson)));
  }

  public static getCommonWorkshop(processes: Array<{ workshop?: Pick<Workshop, 'code'> | null }>): string | undefined {
    if (processes.length === 1) {
      return processes[0].workshop?.code;
    }
    const firstWorkshopId = processes[0]?.workshop?.code ?? '';
    return processes.every(
      (process: { workshop?: Pick<Workshop, 'code'>  | null }) => process?.workshop?.code === firstWorkshopId
    ) ? firstWorkshopId : undefined;
  }
}
