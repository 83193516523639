import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { CxMenuModule } from '@bbraun/cortex/menu';
import { CxProfileDropdownComponent } from '@bbraun/cortex/profile-dropdown';
import { TranslocoModule } from '@ngneat/transloco';
import { OAuthService } from 'angular-oauth2-oidc';

import { environment } from '../../../environments/environment';
import { UserConfigsStoreFacade } from '../../store/user-configs/user-configs-store.facade';

@Component({
  selector: 'app-header-profile',
  standalone: true,
  imports: [
    CommonModule,
    CxProfileDropdownComponent,
    CxMenuModule,
    TranslocoModule
  ],
  templateUrl: './header-profile.component.html',
  styleUrl: './header-profile.component.sass'
})
export class HeaderProfileComponent {

  constructor(
    public readonly userConfigsFacade: UserConfigsStoreFacade,
    private readonly oAuthService: OAuthService
  ) { }

  public onLogout(): void {
    this.oAuthService.logOut();
  }

  public onProfile(): void {
    window.open(environment.profile, '_blank');
  }
}
