import { createAction, props } from '@ngrx/store';

import { CustomerConfig } from '../../shared/interfaces/customer/customer-config';
import { UiConfig } from '../../shared/interfaces/layout/configs/ui-config';
import { HeaderLayout } from '../../shared/interfaces/layout/header/header-layout';

export const removeSplashScreen = createAction('[Layout] Remove Splash Screen');

export const setHeaderLayout = createAction(
  '[Layout] Set Header Layout',
  props<{ headerLayout: HeaderLayout }>()
);

export const mainNavigationDrawerHidden = createAction(
  '[Layout] Hide Main Navigation Drawer',
  props<{ hideMainNavigationDrawer: boolean }>()
);

export const uiConfigsLoaded = createAction(
  '[Layout] UI Configs Loaded',
  props<{ uiConfigs: Array<UiConfig> }>()
);

export const uiConfigChanged = createAction(
  '[Layout] UI Config Changed',
  props<{ uiConfig: UiConfig }>()
);

export const uiConfigUpdated = createAction(
  '[Layout] UI Config Updated',
  props<{ uiConfig: UiConfig }>()
);

export const processCountsChange = createAction('[Layout] Process Counts Change');

export const approvalsRequiredCountLoaded = createAction(
  '[Layout] Approvals Required Count Loaded',
  props<{ approvalsRequiredCount: number }>()
);

export const newPurchasesCountLoaded = createAction(
  '[Layout] New Purchases Count Loaded',
  props<{ newPurchasesCount: number }>()
);

export const setSelectedCustomer = createAction(
  '[Overview Layout] Set Selected Customer ERP',
  props<{ selectedCustomer: CustomerConfig | undefined }>()
);

export const toggleCountrySwitchSidebar = createAction(
  '[Layout] Toggle Country Switch Sidebar',
  props<{ showCountrySwitchSidebar: boolean }>()
);

export const toggleViewSwitchSidebar = createAction(
  '[Layout] Toggle View Switch Sidebar',
  props<{ showViewSwitchSidebar: boolean }>()
);
