<cx-sidebar-container
  #countrySwitchSidebarContainer
  [hideBodyScrollbar]="true">
  <cx-sidebar
    #countrySwitchSidebar
    [fixedInViewport]="true"
    [opened]="true"
    *transloco="let t">
    <cx-sidebar-header class="d-flex justify-content-between align-items-center">
      <span>{{ t('Switch Country') }}</span>
    </cx-sidebar-header>
    <cx-sidebar-content>
      <mat-form-field
        class="country-form w-100">
        <mat-label>{{ t('Country') }}</mat-label>
        <mat-select
          #countrySelect
          required
          [compareWith]="helper.codeMatcher"
          [formControl]="countryControl">
        <mat-option
            *ngFor="let country of userConfigsFacade.superAdminCountriesSignal()"
            [value]="country">
            {{ t(country.name) }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </cx-sidebar-content>
    <cx-sidebar-footer>
      <button
        mat-stroked-button
        (click)="closeSidebar()"
        id="change-order-reference-cancel">
        {{ t('Cancel') }}
      </button>
      <button
        mat-flat-button
        class="cx-button-with-icon"
        color="accent"
        (click)="onSwitchCountryClicked()"
        id="change-order-reference-submit">
        <mat-icon class="cx-icon-in-button" [svgIcon]="'swap_icon_in_button'"/>
        {{ t('Switch') }}
      </button>
    </cx-sidebar-footer>
  </cx-sidebar>
</cx-sidebar-container>
