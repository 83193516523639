<cx-splash-screen
  *ngIf="layoutStoreFacade.showSplashScreen$ | async"
  #splashScreen
  [appName]="APP_NAME"
  (animationDone)="layoutStoreFacade.removeSplashScreen()">
</cx-splash-screen>

<cx-header *transloco="let t" layoutClass="mx-m">
  <cx-header-back-navigator
    *ngIf="headerLayout.hasBackNavigation"
    (click)="navigateBack(headerLayout.backNavigationRoute)">
  </cx-header-back-navigator>
  <cx-header-title>
    <div class="d-flex align-items-center">
      <mat-icon class="mr-xs d-flex align-items-center justify-content-center large-icon" svgIcon="app_icon" />
      {{ APP_NAME }}
    </div>
  </cx-header-title>
  <cx-header-quickbar>
    @if (userConfigsFacade.isSuperAdminAsSignal()) {
      <button
        class="align-self-center mr-s"
        mat-icon-button
        (click)="onSwitchButtonClicked()"
        [matTooltip]="t(userConfigsFacade.isInternalAsSignal() ? 'Switch to External view' : 'Switch to Internal view')">
        <mat-icon svgIcon="swap_horiz"/>
      </button>
    }
    <app-header-profile />
  </cx-header-quickbar>
</cx-header>
<ng-container *ngIf="userConfigsFacade.hasUserEnterRights$ | async; else noEnterRightsPage">
  <div
    *ngIf="navigationStarted && (layoutStoreFacade.hideMainNavigationDrawer$ | async) === false">
    <app-navigation-external
      *ngIf="(userConfigsFacade.isExternal$ | async)">
    </app-navigation-external>
    <app-navigation-internal
      *ngIf="(userConfigsFacade.isInternal$ | async)">
    </app-navigation-internal>
  </div>

  <cx-navigation-drawer-container
    class="main-navigation-drawer-container"
    *ngIf="layoutStoreFacade.uiConfigsLoaded$ | async">
    <router-outlet></router-outlet>
  </cx-navigation-drawer-container>
</ng-container>

<ng-template #noEnterRightsPage>
  <app-no-permission
    [reason]="noAccessReason"
    *ngIf="noAccessReason" />
</ng-template>

@if (layoutStoreFacade.showCountrySwitchSidebarSignal()) {
  <app-country-switch-sidebar></app-country-switch-sidebar>
}
@if (layoutStoreFacade.showSwitchViewSidebarSignal()) {
  <app-switch-view-sidebar></app-switch-view-sidebar>
}
