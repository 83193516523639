import { ProcessStatuses } from '../replenishment-process/process-status';

export enum CustomOverviewCardTypes {
  ALL_PROCESSES = 'ALL PROCESSES'
}

export const OverviewCardTypes = {
  ...ProcessStatuses,
  ...CustomOverviewCardTypes
};

export type OverviewCardType = keyof typeof OverviewCardTypes | CustomOverviewCardTypes;

export const MandatoryOverviewCardTypes: Array<OverviewCardType> = [
  OverviewCardTypes.ALL_PROCESSES
];
