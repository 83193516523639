import { createSelector } from '@ngrx/store';

import { LayoutState } from './layout.reducer';
import { uiConfigKeys } from '../../shared/enums/ui-config/ui-config-keys';
import { CustomerConfig } from '../../shared/interfaces/customer/customer-config';
import { OverviewConfigWithKey } from '../../shared/interfaces/layout/configs/ui-config';
import { getState, State } from '../index';

const getLayoutState = createSelector(getState, (state: State) => state.layout);

export const getShowSplashScreen = createSelector(getLayoutState, (state: LayoutState) => state.showSplashScreen);
export const getHeader = createSelector(getLayoutState, (state: LayoutState) => state.header);
export const getHideMainNavigationDrawer = createSelector(getLayoutState, (state: LayoutState) => state.hideMainNavigationDrawer);
export const getApprovalsRequiredCount = createSelector(getLayoutState, (state: LayoutState) => state.approvalsRequiredCount);
export const getNewPurchasesCount = createSelector(getLayoutState, (state: LayoutState) => state.newPurchasesCount);
export const getUiConfigs = createSelector(getLayoutState, (state: LayoutState) => state.uiConfigs);
export const getUiConfigsLoaded = createSelector(getLayoutState, (state: LayoutState) => state.uiConfigsLoaded);
export const getInternalOverviewConfig = createSelector(
  getUiConfigs,
  (uiConfigs) => uiConfigs.entities[uiConfigKeys.OVERVIEW_PAGE_INTERNAL] as unknown as OverviewConfigWithKey
);
export const getExternalOverviewConfig = createSelector(
  getUiConfigs,
  (uiConfigs) => uiConfigs.entities[uiConfigKeys.OVERVIEW_PAGE_EXTERNAL] as unknown as OverviewConfigWithKey
);
export const getSelectedCustomer = createSelector(
  getLayoutState,
  (state: LayoutState) => state.overviewPage.selectedCustomer
);
export const getSelectedCustomerERP = createSelector(
  getSelectedCustomer,
  (customer: CustomerConfig | undefined) => customer?.erpRef
);
export const getSelectedCustomerName = createSelector(
  getSelectedCustomer,
  (customer: CustomerConfig | undefined) => customer?.name
);
export const getWelcomePageConfig = createSelector(
  getUiConfigs,
  (uiConfigs) => uiConfigs.entities[uiConfigKeys.WELCOME_PAGE]
);
export const getShowCountrySwitchSidebar = createSelector(
  getLayoutState, (state: LayoutState) => state.showCountrySwitchSidebar
);
export const getShowViewSwitchSidebar = createSelector(
  getLayoutState, (state: LayoutState) => state.showViewSwitchSidebar
);
