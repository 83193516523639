import { AzureInsightsConstants } from '../app/shared/constants/azure-insights.constants';
import { CLARITY_DEV_PROJECT_ID } from '../app/shared/constants/clarity.constants';
import {
  AUTH_ISSUER_URL,
  AUTH_LOGOUT_URL,
  AUTH_SCOPE_URL,
  BACKEND_DEV_URLS,
  PROFILE_DEV_URL,
} from '../app/shared/constants/url.constants';

export const environment = {
  production: false,
  backendUrls: BACKEND_DEV_URLS,
  auth: {
    clientId: 'b2407504-bfac-478a-afb8-39e813ad9d67',
    logoutUrl: AUTH_LOGOUT_URL,
    issuer: AUTH_ISSUER_URL,
    scope: AUTH_SCOPE_URL
  },
  monitoring: {
    enable: true,
    azureInsightsKey: AzureInsightsConstants.DEV_KEY
  },
  clarity: {
    enable: true,
    projectId: CLARITY_DEV_PROJECT_ID
  },
  profile: PROFILE_DEV_URL
};
