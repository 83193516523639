import { CurrencyPipe, DatePipe, DecimalPipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withInterceptors } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  provideRouter,
  withComponentInputBinding,
  withDisabledInitialNavigation,
  withInMemoryScrolling
} from '@angular/router';

import { CxMaterialConfigProviders } from '@bbraun/cortex';
import { SNACKBAR_CONFIG } from '@bbraun/cortex/snackbar';
import { HttpService } from '@bbraun/helpex/src/lib/http';
import { provideEffects } from '@ngrx/effects';
import {
  NavigationActionTiming,
  provideRouterStore,
  RouterState
} from '@ngrx/router-store';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { OAuthModule, provideOAuthClient } from 'angular-oauth2-oidc';

import { AppComponent } from './app/app.component';
import { appRoutes } from './app/app.routes';
import { ErrorInterceptor } from './app/shared/interceptors/error.interceptor';
import { superAdminInterceptor } from './app/shared/interceptors/super-admin.interceptor';
import { TranslocoRootModule } from './app/shared/modules/transloco-root.module';
import { ResiCurrencyPipe } from './app/shared/pipes/resi-currency/resi-currency.pipe';
import { ResiDatePipe } from './app/shared/pipes/resi-date/resi-date.pipe';
import { ResiDecimalPipe } from './app/shared/pipes/resi-decimal/resi-decimal.pipe';
import {
  provideClarityScript
} from './app/shared/services/clarity-helper/clarity-helper.service';
import { AppLanguageService } from './app/shared/services/language/app-language.service';
import { reducers } from './app/store';
import { LayoutStoreFacade } from './app/store/layout/layout-store.facade';
import { LayoutEffects } from './app/store/layout/layout.effects';
import { ReasonsStoreFacade } from './app/store/reasons/reasons-store.facade';
import { ReasonsEffects } from './app/store/reasons/reasons.effects';
import { RouterStoreFacade } from './app/store/router/router-store.facade';
import { ROUTER_FEATURE_KEY } from './app/store/router/router.selectors';
import { StatusMapEffects } from './app/store/status-maps/status-map.effects';
import { StatusMapFacade } from './app/store/status-maps/status-map.facade';
import { UserConfigsStoreFacade } from './app/store/user-configs/user-configs-store.facade';
import { UserConfigsEffects } from './app/store/user-configs/user-configs.effects';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

registerLocaleData(localeDe);

const rootModules = [
  BrowserModule,
  BrowserAnimationsModule,
  HttpClientModule,
  TranslocoRootModule,
  OAuthModule.forRoot({
    resourceServer: {
      sendAccessToken: true
    }
  }),
];

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(
      appRoutes,
      withDisabledInitialNavigation(),
      withComponentInputBinding(),
      withInMemoryScrolling({ scrollPositionRestoration: 'top' })
    ),
    provideOAuthClient(),
    importProvidersFrom(...rootModules),
    provideStore(reducers,
      {
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
          strictStateSerializability: true,
          strictActionSerializability: true,
          strictActionWithinNgZone: true,
          strictActionTypeUniqueness: true
        }
      }),
    provideEffects([ StatusMapEffects, UserConfigsEffects, LayoutEffects, ReasonsEffects ]),
    provideStoreDevtools(
      {
        maxAge: 25, // Retains last 25 states
        logOnly: environment.production // Restrict extension to log-only mode
      }
    ),
    provideRouterStore({
      stateKey: ROUTER_FEATURE_KEY,
      routerState: RouterState.Minimal,
      navigationActionTiming: NavigationActionTiming.PreActivation
    }),
    provideHttpClient(
      withInterceptors([ superAdminInterceptor ]),
    ),
    {
      provide: SNACKBAR_CONFIG, useValue: {
        maxOpened: 5,
        closeButton: true
      }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    CxMaterialConfigProviders,
    HttpService,
    LayoutStoreFacade,
    StatusMapFacade,
    RouterStoreFacade,
    UserConfigsStoreFacade,
    AppLanguageService,
    CurrencyPipe,
    ResiCurrencyPipe,
    DatePipe,
    ResiDatePipe,
    DecimalPipe,
    ResiDecimalPipe,
    ReasonsStoreFacade,
    provideClarityScript()
  ],
}).catch(error => console.error(error));
