import { Injectable } from '@angular/core';

import { createSelector, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import {
  mainNavigationDrawerHidden,
  processCountsChange,
  removeSplashScreen,
  setHeaderLayout,
  setSelectedCustomer,
  toggleCountrySwitchSidebar,
  toggleViewSwitchSidebar,
  uiConfigChanged,
} from './layout.actions';
import {
  getHeader,
  getHideMainNavigationDrawer,
  getShowSplashScreen,
  getUiConfigs,
  getInternalOverviewConfig,
  getExternalOverviewConfig,
  getUiConfigsLoaded,
  getSelectedCustomerERP,
  getWelcomePageConfig,
  getApprovalsRequiredCount,
  getNewPurchasesCount,
  getSelectedCustomerName,
  getShowCountrySwitchSidebar,
  getShowViewSwitchSidebar
} from './layout.selectors';
import { ProcessTableName } from '../../shared/enums/process-tables/process-tables-names.enum';
import { CustomerConfig } from '../../shared/interfaces/customer/customer-config';
import {
  OverviewConfig,
  TableConfig,
  TableConfigWithKey,
  WelcomePageConfig,
} from '../../shared/interfaces/layout/configs/ui-config';
import { HeaderLayout } from '../../shared/interfaces/layout/header/header-layout';
import { State } from '../index';

@Injectable()
export class LayoutStoreFacade {
  public showSplashScreen$ = this.store.select(getShowSplashScreen);
  public header$ = this.store.select(getHeader);
  public hideMainNavigationDrawer$ = this.store.select(getHideMainNavigationDrawer);
  public overviewConfigInternalAsSignal = this.store.selectSignal(getInternalOverviewConfig);
  public overviewConfigExternalAsSignal = this.store.selectSignal(getExternalOverviewConfig);
  public welcomePageConfigAsSignal = this.store.selectSignal(getWelcomePageConfig);
  public readonly uiConfigsLoaded$ = this.store.select(getUiConfigsLoaded);
  public readonly selectedCustomerERPSignal = this.store.selectSignal(getSelectedCustomerERP);
  public readonly selectedCustomerNameSignal = this.store.selectSignal(getSelectedCustomerName);
  public readonly approvalsRequiredCountSignal = this.store.selectSignal(getApprovalsRequiredCount);
  public readonly newPurchasesCountSignal = this.store.selectSignal(getNewPurchasesCount);
  public readonly showCountrySwitchSidebarSignal = this.store.selectSignal(getShowCountrySwitchSidebar);
  public readonly showSwitchViewSidebarSignal = this.store.selectSignal(getShowViewSwitchSidebar);

  constructor(
    private store: Store<State>
  ) { }

  public getTableConfig = (configKey: ProcessTableName): Observable<TableConfigWithKey | undefined> => this.store.select(createSelector(
    getUiConfigs, (uiConfigs) => uiConfigs.entities[configKey] as unknown as TableConfigWithKey
  ));

  public removeSplashScreen(): void {
    this.store.dispatch(removeSplashScreen());
  }

  public setHeaderLayout(headerLayout: HeaderLayout): void {
    this.store.dispatch(setHeaderLayout({ headerLayout }));
  }

  public setMainNavigationDrawerVisibility(hideMainNavigationDrawer: boolean): void {
    this.store.dispatch(mainNavigationDrawerHidden({ hideMainNavigationDrawer }));
  }

  public processCountsChange(): void {
    this.store.dispatch(processCountsChange());
  }

  public setUiConfig(configurationKey: string, configuration: OverviewConfig | TableConfig | WelcomePageConfig): void {
    this.store.dispatch(uiConfigChanged({
      uiConfig: {
        configurationKey,
        configuration
      }
    }));
  }

  public setOverviewSelectedCustomer(selectedCustomer: CustomerConfig): void {
    this.store.dispatch(setSelectedCustomer({ selectedCustomer }));
  }

  public clearOverviewSelectedCustomer(): void {
    this.store.dispatch(setSelectedCustomer({ selectedCustomer: undefined }));
  }

  public openCountrySwitchSidebar(): void {
    this.store.dispatch(toggleCountrySwitchSidebar({ showCountrySwitchSidebar: true }));
  }

  public closeCountrySwitchSidebar(): void {
    this.store.dispatch(toggleCountrySwitchSidebar({ showCountrySwitchSidebar: false }));
  }

  public openViewSwitchSidebar(): void {
    this.store.dispatch(toggleViewSwitchSidebar({ showViewSwitchSidebar: true }));
  }

  public closeViewSwitchSidebar(): void {
    this.store.dispatch(toggleViewSwitchSidebar({ showViewSwitchSidebar: false }));
  }
}
