import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { loadReasons } from './reasons.actions';
import { ReasonsState } from './reasons.reducer';
import { getReasons } from './reasons.selectors';

@Injectable()
export class ReasonsStoreFacade {
  public readonly reasons$ = this.store.select(getReasons);
  constructor(private store: Store<ReasonsState>) {}

  public loadProcessTypeChangeReasons(): void {
    this.store.dispatch(loadReasons());
  }
}
