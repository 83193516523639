import { EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { reasonsAdapter } from './adapter/reasons.adapter';
import * as ReasonActions from './reasons.actions';
import {
  ProcessTypeChangeReasons
} from '../../shared/enums/replenishment-process/process-type-change-reasons.enum';

export interface ReasonsState extends EntityState<ProcessTypeChangeReasons> {
  isLoading: boolean;
  error: string | null;
}

export const initialReasonState: ReasonsState = reasonsAdapter.getInitialState({
  isLoading: false,
  error: null
});

export const reasonsReducer = createReducer(
  initialReasonState,
  on(ReasonActions.loadReasons, (state) => ({ ...state, isLoading: true })),
  on(ReasonActions.loadReasonsSuccess, (state, { reasons }) => reasonsAdapter.setAll(reasons, { ...state, isLoading: false })),
  on(ReasonActions.loadReasonsFailure, (state, { error }) => ({ ...state, isLoading: false, error }))
);
