import { createAction, props } from '@ngrx/store';

import { ProcessTypeChangeReasons } from '../../shared/enums/replenishment-process/process-type-change-reasons.enum';

export const loadReasons = createAction('[Reasons] Load Reasons');

export const loadReasonsSuccess = createAction(
  '[Reasons] Load Reasons Success',
  props<{ reasons: Array<ProcessTypeChangeReasons> }>()
);

export const loadReasonsFailure = createAction(
  '[Reasons] Load Reasons Failure',
  props<{ error: string }>()
);
