import { Injectable } from '@angular/core';
import { MatSortable } from '@angular/material/sort';

import { HttpService } from '@bbraun/helpex/src/lib/http';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { CustomerIdTypes } from '../../enums/customer-id-types.enum';
import { DeliveryAddress } from '../../interfaces/country/delivery-address';
import { ContactInformation } from '../../interfaces/customer/contact-information';
import { CreateCustomer, CustomerConfig, UpdateCustomer } from '../../interfaces/customer/customer-config';
import { AdditionalMarkingConfig } from '../../interfaces/customer-overview/additional-marking-config';
import { DeliveryTimeConfig } from '../../interfaces/customer-overview/delivery-time-config.interface';
import { OrganisedPickup } from '../../interfaces/customer-overview/organised-pickup.interface';
import { PriceConfig } from '../../interfaces/customer-overview/price-config';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private httpService: HttpService) { }

  public getCustomerConfig(identifier: string, idType: CustomerIdTypes): Observable<CustomerConfig> {
    return this.httpService.request(`v1/configurations/${identifier}`)
      .param('idType', idType)
      .get(environment.backendUrls.CUSTOMER);
  }

  public getDeliveryAddress(configId: string): Observable<DeliveryAddress> {
    return this.httpService.request(`v1/configurations/${configId}/delivery/address`)
      .get(environment.backendUrls.CUSTOMER);
  }

  public updateDeliveryAddress(configId: string, deliveryAddress: DeliveryAddress): Observable<null> {
    return this.httpService
      .request(`v1/configurations/${configId}/delivery/address`)
      .body(deliveryAddress)
      .put(environment.backendUrls.CUSTOMER);
  }

  public getCustomersByCountryCode(countryCode: string, sorting?: MatSortable): Observable<Array<CustomerConfig>> {
    const request =  this.httpService
      .request('v1/configurations')
      .param('countryCode', countryCode);

    if (sorting) {
      request.param('sort', `${sorting.id},${sorting.start}`);
    }

    return request.get(environment.backendUrls.CUSTOMER);
  }

  public getDeliveryTime(configId: string): Observable<DeliveryTimeConfig> {
    return this.httpService.request(`v1/configurations/${configId}/delivery/time`)
      .get(environment.backendUrls.CUSTOMER);
  }

  public saveDeliveryTime(config: DeliveryTimeConfig, configId: string): Observable<void> {
    return this.httpService
      .request(`v1/configurations/${configId}/delivery/time`)
      .body(config)
      .put(environment.backendUrls.CUSTOMER);
  }

  public getPriceConfig(configId: string | undefined): Observable<PriceConfig> {
    return this.httpService.request(`v1/configurations/${configId}/prices`)
      .get(environment.backendUrls.CUSTOMER);
  }

  public updatePriceConfig(configId: string | undefined, priceConfig: Partial<PriceConfig>): Observable<void> {
    return this.httpService.request(`v1/configurations/${configId}/prices`)
      .body(priceConfig)
      .put(environment.backendUrls.CUSTOMER);
  }

  public getOrganisedPickup(configId: string | undefined): Observable<OrganisedPickup> {
    return this.httpService.request(`v1/configurations/${configId}/organized-pickup`)
      .get(environment.backendUrls.CUSTOMER);
  }

  public updateOrganisedPickup(configId: string | undefined, organisedPickup: OrganisedPickup): Observable<void> {
    return this.httpService.request(`v1/configurations/${configId}/organized-pickup`)
      .body(organisedPickup)
      .put(environment.backendUrls.CUSTOMER);
  }

  public getMarkingConfig(configId: string | undefined): Observable<AdditionalMarkingConfig> {
    return this.httpService.request(`v1/configurations/${configId}/additional-marking`)
      .get(environment.backendUrls.CUSTOMER);
  }

  public updateMarkingConfig(configId: string | undefined, markingConfig: Partial<AdditionalMarkingConfig>): Observable<void> {
    return this.httpService.request(`v1/configurations/${configId}/additional-marking`)
      .body(markingConfig)
      .put(environment.backendUrls.CUSTOMER);
  }

  public getContactInformation(configId: string | undefined): Observable<ContactInformation> {
    return this.httpService.request(`v1/configurations/${configId}/contact-information`)
      .get(environment.backendUrls.CUSTOMER);
  }

  public updateContactInformation(configId: string | undefined, contactInformation: ContactInformation): Observable<void> {
    return this.httpService.request(`v1/configurations/${configId}/contact-information`)
      .body(contactInformation)
      .put(environment.backendUrls.CUSTOMER);
  }

  public createCustomer(customerProfileConfig: CreateCustomer): Observable<CustomerConfig> {
    return this.httpService.request('v1/configurations')
      .body(customerProfileConfig)
      .post(environment.backendUrls.CUSTOMER);
  }

  public updateCustomer(customerProfileConfig: UpdateCustomer): Observable<CustomerConfig> {
    return this.httpService.request(`v1/configurations/${customerProfileConfig.id}`)
      .body(customerProfileConfig)
      .put(environment.backendUrls.CUSTOMER);
  }
}
