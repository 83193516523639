import { DecimalPipe } from '@angular/common';
import { computed, inject, Pipe, PipeTransform, Signal } from '@angular/core';

import { UserConfigsStoreFacade } from '../../../store/user-configs/user-configs-store.facade';

@Pipe({
  name: 'resiDecimal',
  standalone: true
})
export class ResiDecimalPipe implements PipeTransform {
  private decimalPipe = inject(DecimalPipe);
  private userFacade = inject(UserConfigsStoreFacade);

  public transform(value: number): Signal<string | null> {
    const countryCode = this.userFacade.userLocationSignal();

    return computed(() => {
      return countryCode === 'UK'
        ? this.decimalPipe.transform(value, undefined, 'en')
        : this.decimalPipe.transform(value, undefined, 'de');
    });
  }
}
