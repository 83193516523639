import { createAction, props } from '@ngrx/store';

import { Country } from '../../shared/interfaces/country/country';
import { CountryConfig } from '../../shared/interfaces/country-config/country-config';
import { CustomerConfig } from '../../shared/interfaces/customer/customer-config';
import { User } from '../../shared/interfaces/user/user';

export const userDataLoaded = createAction(
  '[User Configs] User Data Loaded',
  props<{ user: User }>());

export const superAdminDataLoaded = createAction(
  '[User Configs] Super Admin Data Loaded',
  props<{ user: User }>());

export const externalUserEmployerLoaded = createAction(
  '[User Configs] External User Employer Loaded',
  props<{ externalUserEmployerConfig: CustomerConfig }>());

export const externalUserCountryLoaded = createAction(
  '[User Configs] External User Country Loaded',
  props<{ externalUserCountryConfig: CountryConfig }>());

export const superAdminCountriesLoaded = createAction(
  '[User Configs] Super Admin Countries Loaded',
  props<{ superAdminCountries: Array<Country> }>()
);
