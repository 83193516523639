<cx-message *transloco="let t">
  <cx-message-title class="text-center">{{ t(reason) }}</cx-message-title>
  <cx-message-image class="mt-ml"
    [imageSrc]="'assets/images/no-permission-page/no-permission-page-illustration.svg'"></cx-message-image>
  <cx-message-text>
    <div class="text-center font-l">
      {{ t('It seems that you don\'t have rights. Let\'s solve this problem together.') }}
    </div>
    <div class="text-center font-l">
      {{ t('Please contact the support team') }} <a href="mailto:replenishment-support@bbraun.com"
        class="link">replenishment-support&#64;bbraun.com</a> {{ t('to request') }}
    </div>
    <div class="text-center font-l pb-ml">
      {{ t('your permission.') }}
    </div>
  </cx-message-text>
</cx-message>
