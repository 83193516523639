import { DatePipe } from '@angular/common';
import { computed, inject, Pipe, PipeTransform, Signal } from '@angular/core';

import { UserConfigsStoreFacade } from '../../../store/user-configs/user-configs-store.facade';

@Pipe({
  name: 'resiDate',
  standalone: true
})
export class ResiDatePipe implements PipeTransform {
  private datePipe = inject(DatePipe);
  private userFacade = inject(UserConfigsStoreFacade);

  public transform(value: Date | string | undefined): Signal<string | null> {

    return computed(() => {
      const countryCode = this.userFacade.userLocationSignal();

      return countryCode === 'CH'
        ? this.datePipe.transform(value, 'dd.MM.yyyy')
        : this.datePipe.transform(value, 'dd/MM/yyyy');
    });
  }
}
