import { Injectable } from '@angular/core';

import { SETUP_COUNTRIES } from '../../constants/country-setup/country-setup.constants';
import { UserRolesEnum } from '../../enums/user-roles/user-roles.enum';
import { Country } from '../../interfaces/country/country';

@Injectable()
export class HelperService {

  private static readonly bbraunManufacturerPart = /b\.( )*braun/i;
  private static readonly aesculapManufacturerPart = 'aesculap';

  public static isBBraunItem(manufacturer: string): boolean {
    if (manufacturer?.length > 0) {
      const containsBBraun = this.bbraunManufacturerPart.test(manufacturer);
      const containsAesculap = manufacturer?.toLowerCase().includes(this.aesculapManufacturerPart);
      return containsBBraun || containsAesculap;
    }
    return false;
  }

  public static isInternalUser(userRole: UserRolesEnum | undefined): boolean {
    if (userRole === undefined) {
      return false;
    }

    return [
      UserRolesEnum.SUPER_ADMIN,
      UserRolesEnum.COUNTRY_ADMIN,
      UserRolesEnum.SERVICE_EMPLOYEE
    ].includes(userRole);
  }

  public static isExternalUser(userRole: UserRolesEnum | undefined): boolean {
    if (userRole === undefined) {
      return false;
    }

    return [
      UserRolesEnum.EXTERNAL_USER_REPAIR,
      UserRolesEnum.SUPER_ADMIN_AS_EXTERNAL
    ].includes(userRole);
  }

  public codeMatcher(object1: { code: string }, object2: { code: string }): boolean {
    return object1 && object2 && object1.code == object2.code;
  }

  public idMatcher(object1: { id: string }, object2: { id: string }): boolean {
    return object1 && object2 && object1.id == object2.id;
  }

  public getCountryByName(name: string): Country | null {
    return SETUP_COUNTRIES.find((country) => country.name === name) ?? null;
  }

  public static getCountryByCode(code: string): Country | null {
    return SETUP_COUNTRIES.find((country) => country.code === code) ?? null;
  }

  /*
  Method to round a number to a specific precision.
  Exponential notation is used in order to scale UP the number, round it, and then scale it DOWN.
  For instance: 5.005 is rounded to 5.01 with a precision of 2.
  */
  public roundNumber(value: number, precision: number): number {
    return Number(Math.round(Number(value + 'e' + precision)) + 'e-' + precision);
  }
}
